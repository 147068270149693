<template>
  <div class="footer">
    <div>
      Copyright &copy; 2020 - {{ year }}, Billmann Event GmbH. All Rights Reserved.
    </div>
    <div>
      <a href="https://www.billmann-event.de/impressum/" class="mr-1">Legal Notice</a>
      <a href="https://www.billmann-event.de/datenschutz/" class="mx-1">Data Privacy</a>
      <a href="https://www.billmann-event.de/agb/" class="ml-1">Terms of Service</a>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'

export default {
  name: 'Footer',
  computed: {
    year() {
      return new Date().getFullYear()
    }
  }
}
</script>

<style scoped>
</style>
