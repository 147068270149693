<template>
  <div>
    <b-container fluid>
      <b-row v-if="event">
        <General ref="general" :event.sync="event"></General>
        <Templates ref="templates" :event.sync="event"></Templates>
        <Website ref="website" :event.sync="event"></Website>
        <Authentication ref="authentication" :event.sync="event"></Authentication>
        <Confirm ref="confirm" v-if="event.has_confirm" :event.sync="event"></Confirm>
        <DynamicContent ref="dynamicContent" v-if="event.has_dynamic_content" :event.sync="event"></DynamicContent>
        <ParticleEffects ref="particleEffects" v-if="event.has_particle_effects" :event.sync="event"></ParticleEffects>
        <LiveChat ref="liveChat" v-if="event.has_live_chat" :event.sync="event"></LiveChat>
        <FAQ ref="faq" v-if="event.has_faq" :event.sync="event"></FAQ>
        <Survey ref="survey" v-if="event.has_survey" :event.sync="event"></Survey>
      </b-row>
    </b-container>
    <b-modal id="discard-changes" size="md" title="Decide what to do with unsaved changes." hide-footer>
      <div class="d-flex flex-column">
        <b-container fluid>
          <b-row class="mb-3 align-items-middle" v-for="(modification, idx) in modifications" :key="idx">
            <b-col class="col-6 pl-0 pr-3">
              <p :style="{'font-size': '20px'}"><strong>{{modification.name}}</strong></p>
            </b-col>
            <b-col class="col-3 pl-0 pr-3">
              <b-button class="w-100" variant="success" @click="modification.save(); modifications.splice(idx, 1); modifications.length == 0 ? $bvModal.hide('discard-changes') : 0"> 
                Save
              </b-button>
            </b-col>
            <b-col class="col-3 p-0">
              <b-button class="w-100" variant="danger" @click="modification.discard(); modifications.splice(idx, 1); modifications.length == 0 ? $bvModal.hide('discard-changes') : 0"> 
                Discard
              </b-button>
            </b-col>
          </b-row>
        </b-container>
        <b-button class="align-self-end mt-auto" @click="$bvModal.hide('discard-changes')">Review</b-button>
      </div>
    </b-modal>
  </div>
</template>
<script>
import { API } from 'aws-amplify'

import Authentication from '@/components/event/settings/Authentication.vue';
import Confirm from '@/components/event/settings/Confirm.vue';
import DynamicContent from '@/components/event/settings/DynamicContent.vue';
import FAQ from '@/components/event/settings/FAQ.vue';
import General from '@/components/event/settings/General.vue';
import LiveChat from '@/components/event/settings/LiveChat.vue';
import ParticleEffects from '@/components/event/settings/ParticleEffects.vue';
import Survey from '@/components/event/settings/Survey.vue';
import Templates from '@/components/event/settings/Templates.vue';
import Website from '@/components/event/settings/Website.vue';

export default {
  components: {
    Authentication,
    Confirm,
    DynamicContent,
    FAQ,
    General,
    LiveChat,
    ParticleEffects,
    Survey,
    Templates,
    Website,
  },
  data() {
    return {
      event: null,
      modifications: [],
    };
  },
  methods: {
    isDirty() {
      const modifications = [];
      if (this.$refs.general && this.$refs.general.isDirty()) {
        modifications.push({
          name: "General",
          save: () => this.$refs.general.save(),
          discard: () =>this.$refs.general.discard(),
        });
      }
      if (this.$refs.templates && this.$refs.templates.isDirty()) {
        modifications.push({
          name: "E-Mail templates",
          save: () => this.$refs.templates.save(),
          discard: () =>this.$refs.templates.discard(),
        });
      }
      if (this.$refs.website && this.$refs.website.isDirty()) {
        modifications.push({
          name: "Website",
          save: () => this.$refs.website.save(),
          discard: () =>this.$refs.website.discard(),
        });
      }
      if (this.$refs.authentication && this.$refs.authentication.isDirty()) {
        modifications.push({
          name: "Authentication",
          save: () => this.$refs.authentication.save(),
          discard: () =>this.$refs.authentication.discard(),
        });
      }
      if (this.$refs.confirm && this.$refs.confirm.isDirty()) {
        modifications.push({
          name: "Confirm",
          save: () => this.$refs.confirm.save(),
          discard: () =>this.$refs.confirm.discard(),
        });
      }
      if (this.$refs.dynamicContent && this.$refs.dynamicContent.isDirty()) {
        modifications.push({
          name: "Backend triggered Content",
          save: () => this.$refs.dynamicContent.save(),
          discard: () =>this.$refs.dynamicContent.discard(),
        });
      }
      if (this.$refs.particleEffects && this.$refs.particleEffects.isDirty()) {
        modifications.push({
          name: "Particle Effects",
          save: () => this.$refs.particleEffects.save(),
          discard: () =>this.$refs.particleEffects.discard(),
        });
      }
      if (this.$refs.liveChat && this.$refs.liveChat.isDirty()) {
        modifications.push({
          name: "Live Chat",
          save: () => this.$refs.liveChat.save(),
          discard: () =>this.$refs.liveChat.discard(),
        });
      }
      if (this.$refs.faq && this.$refs.faq.isDirty()) {
        modifications.push({
          name: "Q&A",
          save: () => this.$refs.faq.save(),
          discard: () =>this.$refs.faq.discard(),
        });
      }
      if (this.$refs.survey && this.$refs.survey.isDirty()) {
        modifications.push({
          name: "Survey",
          save: () => this.$refs.survey.save(),
          discard: () =>this.$refs.survey.discard(),
        });
      }

      this.modifications = modifications;

      return modifications.length > 0;
    },
    beforeUnloadHandler(event) {
      if (this.isDirty()) {
        this.$nextTick(() => {
          this.$bvModal.show("discard-changes");
        });
        
        event.preventDefault();
        // Chrome requires returnValue to be set
        event.returnValue = '';
      } else {
        delete event.returnValue;
      }
    }
  },
  created() {
    API.get('APIGateway', `/events/${this.$store.state.currentEvent}`, {})
    .then( response => {
      this.event = response;
    })
    .catch( e => {
      console.error(e);
    });

    window.addEventListener("beforeunload", this.beforeUnloadHandler);
  },
  beforeDestroy() {
    window.removeEventListener("beforeunload", this.beforeUnloadHandler);
  },
  beforeRouteLeave(to, from, next) {
    if (this.isDirty()) {
      this.$bvModal.show("discard-changes");
    } else {
      next();
    }
  },
}
</script>
<style scoped>

</style>