<template>
  <b-col class="col-6 pt-3 pb-3">
    <b-modal v-if="currentTrigger" size="xl" id="edit-trigger-modal" title="Edit Trigger" hide-footer>
      <div class="d-flex flex-column">
        <b-form-group 
          class="w-100"
          label="Enter Name:"
          label-cols="2"
          label-class="font-weight-bold"
          content-cols="10">
          <b-form-input
            id="name"
            v-model="currentTrigger.name"
            placeholder="Enter Name"
          ></b-form-input>
        </b-form-group>
        <b-form-group 
          class="w-100"
          label="Commands:"
          label-cols="2"
          label-class="font-weight-bold"
          content-cols="10">
          <div class="mb-3" v-for="(command, i) of currentTrigger.commands" :key="i">
            <b-container fluid>
              <b-row>
                <div class="col-9 pl-0 pr-3">
                  <b-button class="w-100" v-b-toggle="'command-edit'+i">Command {{i+1}}</b-button>
                </div>
                <div class="col-3 p-0">
                  <b-button class="w-100" @click="currentTrigger.commands.splice(i, 1)"> Remove </b-button>
                </div>
              </b-row>
            </b-container>
            <b-collapse accordion="command-accordion" :id="'command-edit'+i" class="mt-3">
              <b-card>
                <b-form-group 
                  class="w-100"
                  label="Element ID:"
                  label-cols="2"
                  label-class="font-weight-bold"
                  content-cols="10">
                  <b-form-input
                    v-model="command.elementId"
                    placeholder="Enter Element ID"
                  ></b-form-input>
                </b-form-group>
                <b-form-group 
                  class="w-100"
                  label="Remove Classes:"
                  label-cols="2"
                  label-class="font-weight-bold"
                  content-cols="10">
                  <b-form-input
                    v-model="command.removeClasses"
                    placeholder="Enter Classes to be removed(space separated)"
                  ></b-form-input>
                </b-form-group>
                <b-form-group 
                  class="w-100"
                  label="Add Classes:"
                  label-cols="2"
                  label-class="font-weight-bold"
                  content-cols="10">
                  <b-form-input
                    v-model="command.addClasses"
                    placeholder="Enter Classes to be added(space separated)"
                  ></b-form-input>
                </b-form-group>
                <b-form-group 
                  class="w-100"
                  label="Source:"
                  label-cols="2"
                  label-class="font-weight-bold"
                  content-cols="10">
                  <b-form-input
                    v-model="command.source"
                    placeholder="Enter Source"
                  ></b-form-input>
                </b-form-group>
                <b-form-group 
                  class="w-100"
                  label="Delay:"
                  label-cols="2"
                  label-class="font-weight-bold"
                  content-cols="10">
                  <b-form-input
                    step="0.1"
                    v-model.number="command.delay"
                    type="number"
                    placeholder="Enter Delay after command"
                  ></b-form-input>
                </b-form-group>
              </b-card>
            </b-collapse>
          </div>
          <b-button class="w-100" @click="currentTrigger.commands.push({elementId: '', removeClasses: '', addClasses: '', source: '', delay: 0})">Add Command</b-button>
        </b-form-group>
        <b-button class="align-self-end mt-auto" @click="$bvModal.hide('edit-trigger-modal')">Ok</b-button>
      </div>
    </b-modal>
    <b-card no-body align="center" class="h-100">
      <b-card-body class="d-flex flex-column">
        <h3><strong>Backend-triggered Content</strong></h3>
        <b-form-group 
          class="w-100"
          label="Triggers:"
          label-cols="2"
          label-class="font-weight-bold"
          content-cols="10"
        >
          <b-container fluid>
            <b-row class="mb-3" v-for="(trigger, i) in dynamicContent.triggers" :key="i">
              <b-col class="col-9 pl-0 pr-3">
                <b-button v-if="trigger.name" class="w-100 h-100" @click="currentTrigger=trigger" v-b-modal="'edit-trigger-modal'"> 
                  {{trigger.name}} 
                </b-button>
                <b-button v-if="!trigger.name" class="font-weight-light w-100 h-100" @click="currentTrigger=trigger" v-b-modal="'edit-trigger-modal'"> 
                  <em>Empty Trigger</em>
                </b-button>
              </b-col>
              <b-col class="col-3 p-0">
                <b-button class="w-100" @click="dynamicContent.triggers.splice(i, 1)"> Remove </b-button>
              </b-col>
            </b-row>
          </b-container>  
          <b-button class="w-100" @click="dynamicContent.triggers.push({name: '', commands: []})">Add Trigger</b-button>
        </b-form-group>

        <b-button class="align-self-end mt-auto" variant="success" @click="save()">Save</b-button>
      </b-card-body>
    </b-card>
  </b-col>
</template>
<script>
import { API } from 'aws-amplify';
import stringify from 'json-stable-stringify';

export default {
  props: {
    event: {
      type: Object,
    },
  },
  data() {
    return {
      dynamicContent: null,
      currentTrigger: null,
    }
  },
  methods: {
    encodeCommands(commands) {
      return commands.map(x => {
        const removeClassesArray = x.removeClasses.trim().split(" ").filter(y => y);
        const addClassesArray = x.addClasses.trim().split(" ").filter(y => y);

        return {
          elementId: x.elementId,
          removeClasses: removeClassesArray,
          addClasses: addClassesArray,
          source: x.source,
          delay: x.delay
        }
      });
    },
    decodeCommands(commands) {
      return commands.map(x => {
        const removeClasses = x.removeClasses.reduce((acc, x) => acc + x + " ", "").trim();
        const addClasses = x.addClasses.reduce((acc, x) => acc + x + " ", "").trim();

        return {
          elementId: x.elementId,
          removeClasses: removeClasses,
          addClasses: addClasses,
          source: x.source,
          delay: x.delay
        }
      });
    },
    load(initial=false) {
      if (!initial && this.isDirty()) {
        return;
      }
      if(this.event.has_dynamic_content && this.event.dynamic_content) {
        const triggers = JSON.parse(JSON.stringify(this.event.dynamic_content.triggers));
        this.dynamicContent = { 
          triggers: triggers.map(x => {
            return {
              name: x.name,
              commands: this.decodeCommands(x.commands)
            }
          })
        };
      } else {
        this.dynamicContent = {
          "triggers": []
        }
      }
    },
    isDirty() {
      if(this.event.has_dynamic_content && this.event.dynamic_content) {
        const triggers = JSON.parse(JSON.stringify(this.event.dynamic_content.triggers));
        return stringify({ 
          triggers: triggers.map(x => {
            return {
              name: x.name,
              commands: this.decodeCommands(x.commands)
            }
          })
        }) !== stringify(this.dynamicContent);
      } else {
        return stringify({
          triggers: []
        }) !== stringify(this.dynamicContent);
      }
    },
    discard() {
      this.load(true);
    },
    async save() {
      await API.put('APIGateway', `/events/${this.$store.state.currentEvent}`, { body: {
        dynamic_content: {
          triggers: this.dynamicContent.triggers.map(x => {
            return {
              name: x.name,
              commands: this.encodeCommands(x.commands)
            }
          })
        }
      }})
      .then( response => {
        this.$emit("update:event", response);

        this.$bvToast.toast(`Successfully updated dynamic content settings.`, {
          title: `Success!`,
          toaster: "b-toaster-bottom-right",
          variant: "success",
          solid: true
        });
      })
      .catch( e => {
        this.$bvToast.toast(`Failed to update dynamic content settings.`, {
          title: `Error!`,
          toaster: "b-toaster-bottom-right",
          variant: "error",
          solid: true
        });
      });
    },
  },
  watch: {
    event() {
      this.load();
    },
  },
  created() {
    this.load(true);
  }
};
</script>
<style scoped>
</style>