<template>
  <div>
    <b-modal v-if="editTranslation" size="xl" id="edit-translation-modal" title="Edit Translation" hide-footer>
      <b-form-group 
        class="w-100"
        label="Name:"
        label-cols="2"
        label-class="font-weight-bold"
        content-cols="10">
        <b-form-input v-model="editTranslation.name"></b-form-input>
      </b-form-group>
      <b-form-group 
        class="w-100"
        label="Description:"
        label-cols="2"
        label-class="font-weight-bold"
        content-cols="10">
        <b-form-input v-model="editTranslation.description"></b-form-input>
      </b-form-group>
      <b-form-group 
        class="w-100"
        label="Languages:"
        label-cols="2"
        label-class="font-weight-bold"
        content-cols="10">
        <div class="mb-3" v-for="(language, i) of editTranslation.languages" :key="i">
          <b-container fluid>
            <b-row>
              <div class="col-9 pl-0 pr-3">
                <b-button class="w-100 h-100" v-b-toggle="'language-edit'+i">{{language.language || "Language "+ i}}</b-button>
              </div>
              <div class="col-3 p-0">
                <b-button class="w-100" @click="editTranslation.languages.splice(i, 1)"> Remove </b-button>
              </div>
            </b-row>
          </b-container>
          <b-collapse accordion="language-accordion" :id="'language-edit'+i" class="mt-3">
            <b-card>
              <b-form-group 
                class="w-100"
                label="Language:"
                label-cols="2"
                label-class="font-weight-bold"
                content-cols="10">
                <b-form-select class="mb-3" v-model="language.language" :options="languageOptions"></b-form-select>
              </b-form-group>
              <b-form-group 
                class="w-100"
                label="Value:"
                label-cols="2"
                label-class="font-weight-bold"
                content-cols="10">
                <b-form-input
                  v-model="language.value"
                  placeholder="Enter value"
                ></b-form-input>
              </b-form-group>
            </b-card>
          </b-collapse>
        </div>
        <b-button class="w-100" @click="editTranslation.languages.push({language: '', value: ''})">Add Language</b-button>
      </b-form-group>
      <b-button class="mr-2" @click="saveTranslations(); $bvModal.hide('edit-translation-modal')">OK</b-button>
      <b-button @click="$bvModal.hide('edit-translation-modal')">Cancel</b-button>
    </b-modal>
    <b-modal @show="newTranslation={name:'',description: '', languages: []}" size="xl" id="create-translation-modal" title="Create Translation" hide-footer>
      <b-form-group 
        class="w-100"
        label="Name:"
        label-cols="2"
        label-class="font-weight-bold"
        content-cols="10">
        <b-form-input v-model="newTranslation.name"></b-form-input>
      </b-form-group>
      <b-form-group 
        class="w-100"
        label="Description:"
        label-cols="2"
        label-class="font-weight-bold"
        content-cols="10">
        <b-form-input v-model="newTranslation.description"></b-form-input>
      </b-form-group>
      <b-form-group 
        class="w-100"
        label="Languages:"
        label-cols="2"
        label-class="font-weight-bold"
        content-cols="10">
        <div class="mb-3" v-for="(language, i) of newTranslation.languages" :key="i">
          <b-container fluid>
            <b-row>
              <div class="col-9 pl-0 pr-3">
                <b-button class="w-100 h-100" v-b-toggle="'language-edit'+i">{{language.language || "Language "+ i}}</b-button>
              </div>
              <div class="col-3 p-0">
                <b-button class="w-100" @click="newTranslation.languages.splice(i, 1)"> Remove </b-button>
              </div>
            </b-row>
          </b-container>
          <b-collapse accordion="language-accordion" :id="'language-edit'+i" class="mt-3">
            <b-card>
              <b-form-group 
                class="w-100"
                label="Language:"
                label-cols="2"
                label-class="font-weight-bold"
                content-cols="10">
                <b-form-select class="mb-3" v-model="language.language" :options="languageOptions"></b-form-select>
              </b-form-group>
              <b-form-group 
                class="w-100"
                label="Value:"
                label-cols="2"
                label-class="font-weight-bold"
                content-cols="10">
                <b-form-input
                  v-model="language.value"
                  placeholder="Enter value"
                ></b-form-input>
              </b-form-group>
            </b-card>
          </b-collapse>
        </div>
        <b-button class="w-100" @click="newTranslation.languages.push({language: '', value: ''})">Add Language</b-button>
      </b-form-group>
      <b-button class="mr-2" @click="translations.push(newTranslation); saveTranslations(); $bvModal.hide('create-translation-modal')">OK</b-button>
      <b-button @click="$bvModal.hide('create-translation-modal')">Cancel</b-button>
    </b-modal>
    <b-modal id="delete-translation-modal" title="Delete Translations" hide-footer>
      <p>Are you sure you want to delete ALL selected translations?</p>
      <b-button class="mr-1" variant="danger" @click="deleteSelectedTranslations(); saveTranslations(); $bvModal.hide('delete-translation-modal')">Confirm</b-button>
      <b-button @click="$bvModal.hide('delete-translation-modal')">Cancel</b-button>
    </b-modal>
    <Table ref="table" :columns="columns" :rows="rows" :actions="actions"></Table>
  </div>
</template>
<script>
import { API } from 'aws-amplify'

export default {
  data() {
    return {
      translations: [],
      newTranslation: {
        name: "",
        description: "",
        languages: []
      },
      editTranslation: null,
      languageOptions: [
        {value: "", text: "Please select a language"},
        {value: "en", text: "English"},
        {value: "es", text: "Spanish"},
        {value: "es-lat", text: "Spanish (Latin America)"},
        {value: "fr", text: "French"},
        {value: "it", text: "Italian"},
        {value: "pt", text: "Portuguese"},
        {value: "ru", text: "Russian"},
        {value: "pl", text: "Polish"},
        {value: "tr", text: "Turkish"},
        {value: "ar", text: "Arabic"},
        {value: "jp", text: "Japanese"},
        {value: "zh", text: "Chinese"},
        {value: "hi", text: "Hindi"},
        {value: "ko", text: "Korean"},
        {value: "el", text: "Greek"},
        {value: "sv", text: "Swedish"},
        {value: "de", text: "German"},
        {value: "nl", text: "Dutch"},
        {value: "da", text: "Danish"}
      ]
    }
  },
  computed: {
    rows() {
      return this.translations;
    },
    columns() {
      const result = [];
      result.push({
        name: "Name",
        value: (row) => row.name
      });
      result.push({
        name: "Description",
        value: (row) => row.description
      });
      return result;
    },
    actions() {
      const result = [];
      result.push({
        name: "Create Translation",
        action: () => this.$bvModal.show('create-translation-modal'),
      });
      result.push({
        name: "Edit Translation",
        action: () => {
          this.editTranslation = this.$refs.table.getVisibleAndSelectedRows()[0];
          this.$nextTick(() => this.$bvModal.show('edit-translation-modal'));
        },
        oneSelected: true,
      });
      result.push({
        name: "Delete Translation",
        action: () => this.$bvModal.show('delete-translation-modal'),
        dangerous: true,
        someSelected: true,
      });
      return result;
    },
  },
  methods: {
    deleteSelectedTranslations() {
      const selected = this.$refs.table.getVisibleAndSelectedRows();
      this.translations = this.translations.filter(x => !selected.includes(x));
    },
    saveTranslations() {
      API.put('APIGateway', `/events/${this.$store.state.currentEvent}`, { body: {
        translations: this.translations.map(x => ({
          name: x.name,
          description: x.description,
          languages: x.languages,
        }))
      }})
      .then( response => {
        this.translations = response.translations.map(x => ({
          name: x.name,
          description: x.description,
          languages: x.languages,
        }));
      })
      .catch( e => {
        console.log(e);
      });
    }
  },
  created() {
    API.get('APIGateway', `/events/${this.$store.state.currentEvent}`, {})
    .then( response => {
      this.translations = response.translations.map(x => ({
        name: x.name,
        description: x.description,
        languages: x.languages,
      }));
    })
    .catch( e => {
      console.error(e);
    });
  }
}
</script>
<style scoped>
.table thead th {
    vertical-align: top;
}
</style>