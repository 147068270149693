<template>
  <div>
    <b-modal @show="template=''" id="send-email-modal" title="Send Email" hide-footer>      
      <p>Select the email template you want to send to the selected users.</p>
      <b-form-select class="mb-3" v-model="template" :options="templateOptions"></b-form-select>
      <b-button class="mr-1" :disabled="!template" @click="sendEmailsToSelectedUsers(template); $bvModal.hide('send-email-modal')">Send</b-button>
      <b-button @click="$bvModal.hide('send-email-modal')">Cancel</b-button>
    </b-modal>
    <b-modal @show="file=null" id="import-users-modal" title="Import Users" hide-footer>      
      <p>Select the CSV or JSON file containing the users (csv requires exactly 2 columns email+lang).</p>
      <b-form-group 
        class="w-100"
        label="Upload:"
        label-cols="2"
        label-class="font-weight-bold"
        content-cols="10"
      >
        <b-form-file
          class="mb-3"
          v-model="file"
          placeholder="Choose file to upload..."
          no-traverse
        ></b-form-file>
      </b-form-group>
      <b-button class="mr-1" :disabled="!file" @click="importUsers(); $bvModal.hide('import-users-modal')">Import</b-button>
      <b-button @click="$bvModal.hide('import-users-modal')">Cancel</b-button>
    </b-modal>
    <b-modal @show="email=''" id="create-user-modal" title="Create User" hide-footer>
      <p>Email:</p>
      <b-form-input
        class="mb-3"
        type="email"
        id="email"
        v-model="email"
        placeholder="Enter Email"
      ></b-form-input>
      <p>First Name:</p>
      <b-form-input
        class="mb-3"
        v-model="firstName"
        placeholder="Enter First Name"
      ></b-form-input>
      <p>Last Name:</p>
      <b-form-input
        class="mb-3"
        v-model="lastName"
        placeholder="Enter Last Name"
      ></b-form-input>
      <p>Language:</p>
      <b-form-select class="mb-3" v-model="lang" :options="languageOptions"></b-form-select>
      <b-button class="mr-1" :disabled="!email||!lang" @click="createUser(email, lang, {email, lang, firstName, lastName}); $bvModal.hide('create-user-modal')">Create</b-button>
      <b-button @click="$bvModal.hide('create-user-modal')">Cancel</b-button>
    </b-modal>
    <b-modal id="delete-user-modal" title="Delete User" hide-footer>
      <p>Are you sure you want to delete ALL selected users?</p>
      <b-button class="mr-1" variant="danger" @click="deleteSelectedUsers(); $bvModal.hide('delete-user-modal')">Confirm</b-button>
      <b-button @click="$bvModal.hide('delete-user-modal')">Cancel</b-button>
    </b-modal>
    <Table ref="table" :columns="columns" :rows="rows" :actions="actions"></Table>
  </div>
</template>

<script>
import { API } from 'aws-amplify'
import parse from 'csv-parse/lib/sync'
import PromiseFileReader from 'promise-file-reader'

export default {
  data() {
    return {
      guests: [],
      email: "",
      file: null,
      lang: "en",
      firstName: "",
      lastName: "",
      template: "",
      templates: [],
      languageOptions: [
        {value: "", text: "Please select a language"},
        {value: "en", text: "English"},
        {value: "es", text: "Spanish"},
        {value: "es-lat", text: "Spanish (Latin America)"},
        {value: "fr", text: "French"},
        {value: "it", text: "Italian"},
        {value: "pt", text: "Portuguese"},
        {value: "ru", text: "Russian"},
        {value: "pl", text: "Polish"},
        {value: "tr", text: "Turkish"},
        {value: "ar", text: "Arabic"},
        {value: "jp", text: "Japanese"},
        {value: "zh", text: "Chinese"},
        {value: "hi", text: "Hindi"},
        {value: "ko", text: "Korean"},
        {value: "el", text: "Greek"},
        {value: "sv", text: "Swedish"},
        {value: "de", text: "German"},
        {value: "nl", text: "Dutch"},
        {value: "da", text: "Danish"}
      ]
    }
  },
  computed: {
    columns() {
      const result = [];
      result.push({
        name: "Email",
        value: (row) => row.email,
      });
      result.push({
        name: "First Name",
        value: (row) => row.form?.firstName || "",
      });
      result.push({
        name: "Last Name",
        value: (row) => row.form?.lastName || "",
      });
      result.push({
        name: "Password",
        value: (row) => row.password,
      });
      this.filteredTemplates.forEach((template) => {
        result.push({
          name: template,
          value: (row) => {
            if (template in row.tracking.email) {
              const trackedEvents = row.tracking.email[template];
              const states = [];
              for (const [state, stateEvents] of Object.entries(trackedEvents)) {
                for (const stateEvent of stateEvents) {
                  states.push([stateEvent["ts"], state]);
                }
              }
              states.sort((lhs, rhs) => lhs[0] < rhs[0] ? -1 : lhs[0] > rhs[0] ? 1 : 0);
              if (states.length === 0) return "not send";
              return states.at(-1)[1];
            }
            return "not send";
          }
        });
      });
      return result;
    },
    rows() {
      return this.guests;
    },
    actions() {
      const result = [];
      result.push({
        name: "Send Email",
        action: () => this.$bvModal.show('send-email-modal'),
        someSelected: true,
      });
      result.push({
        name: "Create User",
        action: () => this.$bvModal.show('create-user-modal')
      });
      result.push({
        name: "Import Users",
        action: () => this.$bvModal.show('import-users-modal')
      });
      result.push({
        name: "Export Users (CSV)",
        action: () => this.exportUsersCSV()
      });
      result.push({
        name: "Export Users (JSON)",
        action: () => this.exportUsersJSON()
      });
      result.push({
        name: "Delete Users",
        action: () => this.$bvModal.show('delete-user-modal'),
        dangerous: true,
        someSelected: true,
      });

      return result;
    },
    filteredTemplates() {
      return this.templates.filter( x => {
        return x != "MagicLink";
      });
    },
    templateOptions() {
      return this.filteredTemplates.map(x => {
        return {
          value: x,
          text: x
        }
      });
    },
  },
  methods: {
    unflatten(obj) {
      var result = {}
      for (var i in obj) {
        var keys = i.split('.')
        keys.reduce(function(r, e, j) {
          return r[e] || (r[e] = isNaN(Number(keys[j + 1])) ? (keys.length - 1 == j ? obj[i] : {}) : [])
        }, result)
      }
      return result
    },
    exportUsersCSV() {
      this.$refs.table.exportRowsCSV("users.csv");
    },
    exportUsersJSON() {
      this.$refs.table.exportRowsJSON("users.json");
    },
    async importUsers() {
      const toastId = `toast-${Date.now()}`;
      this.$bvToast.toast(`Importing user(s), this might take a while...`, {
        id: toastId,
        title: `In Progress`,
        toaster: "b-toaster-bottom-right",
        variant: "warning",
        noAutoHide: true,
        solid: true
      });

      try {        
        let records = undefined;
        if (this.file.type === "application/json") {
          const data = await PromiseFileReader.readAsText(this.file);
          records = JSON.parse(data);
        } else if (this.file.type === "text/plain" || this.file.type === "text/csv") {
          const data = await PromiseFileReader.readAsText(this.file);
          const flatRecords = parse(data, {
            columns: true,
            skip_empty_lines: true
          });
          records = flatRecords.map((x) => this.unflatten(x));
        } else {
          throw new Error(`Unsupported file format ${this.file.type}`);
        }
        this.file = null;

        let successCount = 0;
        let errorCount = 0;
        const tasks = records.map((record) => this.createUser(record.email, record.lang, record.form, record.emailState, record.confirmState, record.tracking, record.survey, false));
        
        const results = await Promise.allSettled(tasks);
        for (const result of results) {
          if (result.status === 'fulfilled') {
            successCount += 1;
          } else if (result.status === 'rejected') {
            errorCount += 1;
          }
        }
        this.$bvToast.toast(`Successfully created ${successCount} user(s), failed to create ${errorCount} user(s).`, {
          title: `Success!`,
          toaster: "b-toaster-bottom-right",
          variant: "success",
          solid: true
        });
      } catch (e) {
        console.error(e);
        this.$bvToast.toast(`Failed to import users.`, {
          title: `Error!`,
          toaster: "b-toaster-bottom-right",
          variant: "error",
          solid: true
        });
      } finally {
        this.$bvToast.hide(toastId);
      }
    },
    async createUser(email, lang, form, emailState, confirmState, tracking, survey, showToast=true) {
      try {
        const body = {
          email: email,
          lang: lang || "en",
          form: form,
          survey: survey,
          email_state: emailState,
          confirm_state: confirmState,
          tracking: tracking,
        }

        const result = await API.post('APIGateway', `/events/${this.$store.state.currentEvent}/guestlist`, {
          body: body
        })

        this.guests.push({
          email: result.email,
          lang: result.lang,
          form: result.form,
          survey: result.survey,
          emailState: result.email_state,
          confirmState: result.confirm_state,
          password: result.password,
          tracking: result.tracking,
        });
        if (showToast) {
          this.$bvToast.toast(`Successfully created user ${email}.`, {
            title: `Success!`,
            toaster: "b-toaster-bottom-right",
            variant: "success",
            solid: true
          });
        }
      } catch (e) {
        console.error(e);
        if (showToast) {
          this.$bvToast.toast(`Failed to create user ${email}.`, {
            title: `Error!`,
            toaster: "b-toaster-bottom-right",
            variant: "error",
            solid: true
          });
        }
        throw e
      }
    },
    async deleteUser(guest, showToast=true) {
      try {
        const result = await API.del('APIGateway', `/events/${this.$store.state.currentEvent}/guestlist/${guest.email}`, {});
        this.guests = this.guests.filter(x => x.email != guest.email);
        if (showToast) {
          this.$bvToast.toast(`Successfully deleted user ${guest.email}.`, {
            title: `Success!`,
            toaster: "b-toaster-bottom-right",
            variant: "success",
            solid: true
          });
        }
      } catch (e) {
        console.error(e);
        if (showToast) {
          this.$bvToast.toast(`Failed to delete user ${guest.email}.`, {
            title: `Error!`,
            toaster: "b-toaster-bottom-right",
            variant: "error",
            solid: true
          });
        }
      }
    },
    async deleteSelectedUsers() {
      const selected = this.$refs.table.getVisibleAndSelectedRows();
      const toastId = `toast-${Date.now()}`;
      this.$bvToast.toast(`Deleting ${selected.length} user(s), this might take a while...`, {
        id: toastId,
        title: `In Progress`,
        toaster: "b-toaster-bottom-right",
        variant: "warning",
        noAutoHide: true,
        solid: true
      });
      
      try {
        const tasks = selected.map((guest) => this.deleteUser(guest, false));
        const results = await Promise.allSettled(tasks);
        let successCount = 0;
        let errorCount = 0;
        for (const result of results) {
          if (result.status === 'fulfilled') {
            successCount += 1;
          } else if (result.status === 'rejected') {
            errorCount += 1;
          }
        }

        this.$bvToast.toast(`Successfully deleted ${successCount} user(s), failed to delete ${errorCount} user(s).`, {
          title: `Success!`,
          toaster: "b-toaster-bottom-right",
          variant: "success",
          solid: true
        });
      } catch(e) {
        console.error(e);
      } finally {
        this.$bvToast.hide(toastId);
      }
    },
    async sendEmailsToSelectedUsers(template) {
      const selected = this.$refs.table.getVisibleAndSelectedRows();

      const toastId = `toast-${Date.now()}`;
      this.$bvToast.toast(`Sending ${selected.length} email(s), this might take a while...`, {
        id: toastId,
        title: `In Progress`,
        toaster: "b-toaster-bottom-right",
        variant: "warning",
        noAutoHide: true,
        solid: true
      });

      try {
        const batches = [];
        for (let i = 0; i < selected.length; i += 100) {
          batches.push(selected.slice(i, i + 100));
        }
        const tasks = batches.map((batch) => API.post('APIGateway', `/events/${this.$store.state.currentEvent}/send-emails`, {
          body: {
            emails: batch.map(user => user.email),
            email_type: template
          }
        }));
        const results = await Promise.allSettled(tasks);

        let successCount = 0;
        let errorCount = 0;
        for (let resultIndex = 0; resultIndex < results.length; resultIndex++) {
          const result = results[resultIndex];
          if (result.status === 'fulfilled') {
            successCount += result.value.succeded.length;
            errorCount += result.value.failed.length;
          } else if (result.status === 'rejected') {
            errorCount += batches[resultIndex].length;
          }
        }

        this.$bvToast.toast(`Successfully sent ${successCount} email(s), failed to send ${errorCount} email(s).`, {
          title: `Success!`,
          toaster: "b-toaster-bottom-right",
          variant: "success",
          solid: true
        });
      } catch (e) {
        console.error(e);
        this.$bvToast.toast(`Failed to send emails.`, {
          title: `Error!`,
          toaster: "b-toaster-bottom-right",
          variant: "error",
          solid: true
        });
      } finally {
        this.$bvToast.hide(toastId);
      }
    }
  },
  created() {
    API.get('APIGateway', `/events/${this.$store.state.currentEvent}/guestlist`, {})
    .then((result) => {
      this.guests = result.guestlist.map(x => {
        return {
          email: x.email,
          lang: x.lang,
          form: x.form,
          survey: x.survey,
          emailState: x.email_state,
          confirmState: x.confirm_state,
          password: x.password,
          tracking: x.tracking,
        }
      })
    })
    .catch((e) => {
      console.log(e)
    })

    API.get('APIGateway', `/events/${this.$store.state.currentEvent}`, {})
    .then((result) => {
      this.templates = [... new Set(result.email_templates.map(x => x.name))];
    })
    .catch((e) => {
      console.log(e)
    })
  }
}
</script>

<style scoped lang="scss">

.table thead th {
    vertical-align: top;
}

</style>
