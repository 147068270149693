<template>
  <b-col class="col-6 pt-3 pb-3">
    <b-modal v-if="currentConfirm" id="edit-confirm-modal" size="xl" title="Edit Confirm" hide-footer>
      <div class="d-flex flex-column">
        <b-form-group 
          class="w-100"
          label="Name:"
          label-cols="2"
          label-class="font-weight-bold"
          content-cols="10">
          <b-form-input v-model="currentConfirm.name"></b-form-input>
        </b-form-group>
        <b-form-group 
          class="w-100"
          label="Form field:"
          label-cols="2"
          label-class="font-weight-bold"
          content-cols="10">
          <b-form-input v-model="currentConfirm.formField"></b-form-input>
        </b-form-group>
        <b-form-group 
          class="w-100"
          label="Form field condition:"
          label-cols="2"
          label-class="font-weight-bold"
          content-cols="10">
          <b-form-select v-model="currentConfirm.formFieldCondition" :options="conditionOptions"></b-form-select>
        </b-form-group>
        <b-form-group 
          class="w-100"
          label="Form field condition value:"
          label-cols="2"
          label-class="font-weight-bold"
          content-cols="10">
          <b-form-input v-model="currentConfirm.formFieldConditionValue"></b-form-input>
        </b-form-group>
        <b-form-group 
          class="w-100"
          label="Button Id:"
          label-cols="2"
          label-class="font-weight-bold"
          content-cols="10">
          <b-form-input v-model="currentConfirm.buttonId"></b-form-input>
        </b-form-group>
        <b-form-group 
          class="w-100"
          label="Expire after:"
          label-cols="2"
          label-class="font-weight-bold"
          content-cols="10">
          <b-form-input v-model.number="currentConfirm.expireDays" type="number" step="1"></b-form-input>
        </b-form-group>
        <b-form-group 
          class="w-100"
          label="Confirm Email:"
          label-cols="2"
          label-class="font-weight-bold"
          content-cols="10">
          <b-form-input v-model="currentConfirm.confirmEmail"></b-form-input>
        </b-form-group>
        <b-form-group 
          class="w-100"
          label="Confirm Page:"
          label-cols="2"
          label-class="font-weight-bold"
          content-cols="10">
          <b-form-input v-model="currentConfirm.confirmPage"></b-form-input>
        </b-form-group>
        <b-form-group 
          class="w-100"
          label="Confirm Success Page:"
          label-cols="2"
          label-class="font-weight-bold"
          content-cols="10">
          <b-form-input v-model="currentConfirm.confirmSuccessPage"></b-form-input>
        </b-form-group>
        <b-form-group 
          class="w-100"
          label="Confirm Error Page:"
          label-cols="2"
          label-class="font-weight-bold"
          content-cols="10">
          <b-form-input v-model="currentConfirm.confirmErrorPage"></b-form-input>
        </b-form-group>
        <b-form-group 
          class="w-100"
          label="Confirm Success Emails:"
          label-cols="2"
          label-class="font-weight-bold"
          content-cols="10">
          <b-form-tags
            id="tags-component-select"
            v-model="currentConfirm.confirmSuccessEmails"
            size="lg"
            class="mb-2"
            add-on-change
            no-outer-focus
          >
            <template v-slot="{ tags, inputAttrs, inputHandlers, disabled, removeTag }">
              <ul v-if="tags.length > 0" class="list-inline d-inline-block mb-2">
                <li v-for="tag in tags" :key="tag" class="list-inline-item">
                  <b-form-tag
                    @remove="removeTag(tag)"
                    :title="tag"
                    :disabled="disabled"
                    variant="info"
                  >{{ tag }}</b-form-tag>
                </li>
              </ul>
              <b-form-select
                v-bind="inputAttrs"
                v-on="inputHandlers"
                :disabled="disabled || emailNameOptions.filter(x => !currentConfirm.confirmSuccessEmails.includes(x.value)).length === 0"
                :options="emailNameOptions.filter(x => !currentConfirm.confirmSuccessEmails.includes(x.value))"
              >
                <template #first>
                  <!-- This is required to prevent bugs with Safari -->
                  <option disabled value="">Choose a Email...</option>
                </template>
              </b-form-select>
            </template>
          </b-form-tags>
        </b-form-group>
        <b-button class="align-self-end mt-auto" @click="$bvModal.hide('edit-confirm-modal')">Ok</b-button>
      </div>
    </b-modal>
    <b-card no-body align="center" class="h-100">
      <b-card-body class="d-flex flex-column">
        <h3><strong>Confirm</strong></h3>
        <b-form-group 
          class="w-100"
          label="Options:"
          label-cols="2"
          label-class="font-weight-bold"
          content-cols="10"
        >
          <b-container fluid>
            <b-row class="mb-3" v-for="(item, i) in confirm" :key="i">
              <b-col class="col-9 pl-0 pr-3">
                <b-button class="w-100 h-100" @click="currentConfirm=item" v-b-modal="'edit-confirm-modal'"> 
                  {{item.name}} 
                </b-button>
              </b-col>
              <b-col class="col-3 p-0">
                <b-button class="w-100" @click="confirm = confirm.filter((_, j) => i != j)"> Remove </b-button>
              </b-col>
            </b-row>
          </b-container>  
          <b-button class="w-100"
            @click="() => {
              confirm.push({
                name: '',
                expireDays: 7,
                formField: '',
                formFieldCondition: 'Present',
                formFieldConditionValue: null,
                buttonId: '',
                confirmEmail: '',
                confirmPage: '',
                confirmSuccessPage: '',
                confirmErrorPage: '',
                confirmSuccessEmails: [],
              });
              currentConfirm = confirm.slice(-1)[0]
            }"
            v-b-modal="'edit-confirm-modal'">Add Option</b-button>
        </b-form-group>

        <b-button class="align-self-end mt-auto" variant="success" @click="save()">Save</b-button>
      </b-card-body>
    </b-card>
  </b-col>
</template>
<script>
import { API } from 'aws-amplify';
import stringify from 'json-stable-stringify';

export default {
  props: {
    event: {
      type: Object,
    },
  },
  data() {
    return {
      confirm: [],
      currentConfirm: null,
      emailTemplates: [],
    }
  },
  computed: {
    emailNameOptions() {
      return this.emailTemplates.map(x => ({value: x.name, text: x.name}));
    },
    conditionOptions() {
      return [
        { value: "Present", text: "Present"},
        { value: "NotPresent", text: "Not Present"},
        { value: "StringEquals", text: "String Equals"},
      ];
    }
  },
  methods: {
    discard() {
      this.load(true);
    },
    load(initial=false) {
      this.emailTemplates = JSON.parse(JSON.stringify(this.event.email_templates));
      if (!initial && this.isDirty()) {
        return;
      }
      if(this.event.has_confirm && this.event.confirm) {
        const confirm = JSON.parse(JSON.stringify(this.event.confirm));
        this.confirm = Object.entries(confirm).map(([name, x]) => {
          x.name = name;
          return x;
        });
      } else {
        this.confirm = [];
      }
    },
    isDirty() {
      if(this.event.has_confirm && this.event.confirm) {
        const confirm = JSON.parse(JSON.stringify(this.event.confirm));
        return stringify(Object.entries(confirm).map(([name, x]) => {
          x.name = name;
          return x;
        })) !== stringify(this.confirm);
      } else {
        return stringify([]) !== stringify(this.confirm);
      }
    },
    async save() {
      await API.put('APIGateway', `/events/${this.$store.state.currentEvent}`, { body: {
        confirm: this.confirm.reduce((val, x) => {
          val[x.name] = x;
          return val;
        }, {})
      }})
      .then( response => {
        this.$emit("update:event", response);

        this.$bvToast.toast(`Successfully updated confirm settings.`, {
          title: `Success!`,
          toaster: "b-toaster-bottom-right",
          variant: "success",
          solid: true
        });
      })
      .catch( e => {
        console.error(e);
        this.$bvToast.toast(`Failed to update confirm settings.`, {
          title: `Error!`,
          toaster: "b-toaster-bottom-right",
          variant: "error",
          solid: true
        });
      });
    }
  },
  watch: {
    event() {
      this.load();
    },
  },
  created() {
    this.load(true);
  }
};
</script>
<style scoped>
</style>