<template>
  <div>
    <b-modal v-if="editQuestion" id="edit-question-modal" title="Edit Question" hide-footer>
      <div>
        <p>Question:</p>
        <b-form-textarea
          id="answer-textarea"
          placeholder="Modify the question..."
          v-model="editQuestion.question"
          rows="3"
          max-rows="6"
        ></b-form-textarea>
        <p>Answer:</p>
        <b-form-textarea
          id="answer-textarea"
          placeholder="Answer the question..."
          v-model="editQuestion.answer"
          rows="3"
          max-rows="6"
        ></b-form-textarea>
        <b-button @click="modifyQuestion(); $bvModal.hide('edit-question-modal')">Edit</b-button>
      </div>
    </b-modal>
    <b-modal id="create-question-modal" title="Create Question" hide-footer @show="question=''; answer=''">
      <p>Question:</p>
      <b-form-textarea
        id="question-textarea"
        placeholder="Type the question..."
        v-model="newQuestion.question"
        rows="3"
        max-rows="6"
      ></b-form-textarea>
      <b-button @click="createQuestion(); $bvModal.hide('create-question-modal')">Create</b-button>
    </b-modal>
    <b-modal id="delete-question-modal" title="Delete Question" hide-footer>
      <p>Are you sure you want to delete ALL selected questions?</p>
      <b-button class="mr-1" variant="danger" @click="deleteSelectedQuestions(); $bvModal.hide('delete-question-modal')">Confirm</b-button>
      <b-button @click="$bvModal.hide('delete-question-modal')">Cancel</b-button>
    </b-modal>
    <Table ref="table" :columns="columns" :rows="rows" :actions="actions"></Table>
  </div>
</template>

<script>
import { API } from 'aws-amplify'
import { mapGetters, mapActions } from 'vuex';

export default {
  computed: {
    ...mapGetters({
      latestMessage: 'getLatestMessage',
    }),
    columns() {
      const result = [];
      result.push({
        name: "Timestamp",
        value: (row) => row.ts
      });
      result.push({
        name: "Question",
        value: (row) => row.question
      });
      result.push({
        name: "Answer",
        value: (row) => row.answer
      });
      return result;
    },
    rows() {
      return this.questions;
    },
    actions() {
      const result = [];
      result.push({
        name: "Create Question",
        action: () => this.$bvModal.show('create-question-modal'),
      });
      result.push({
        name: "Edit Question",
        action: () => {
          this.editQuestion = this.$refs.table.getVisibleAndSelectedRows()[0];
          this.$nextTick(() => this.$bvModal.show('edit-question-modal'));
        },
        oneSelected: true,
      });
      result.push({
        name: "Export Questions",
        action: () => this.$refs.table.exportRowsCSV("questions.csv"),
      });
      result.push({
        name: "Delete Questions",
        action: () => this.$bvModal.show('delete-question-modal'),
        dangerous: true,
        someSelected: true,
      });
      return result;
    },
  },
  data() {
    return {
      questions: [],
      newQuestion: {
        question: '',
      },
      editQuestion: null,
    }
  },
  watch: {
    latestMessage: {
      handler() {
        const data = this.latestMessage;
        if (data.type !== 'FAQ') {
          return;
        }

        if(data.action === "Create") {
          this.questions.unshift({
            question: data.question,
            answer: data.answer,
            id: data.id,
            ts: data.ts,
          });
        } else if(data.action === "Edit") {
          const question = this.questions.find(x => x.id === data.id);
          question.question = data.question;
          question.answer = data.answer;
        } else if(data.action === "Delete") {
          this.questions = this.questions.filter(x => x.id != data.id);
        }
      },
    },
  },
  methods: {
    ...mapActions({
      wsSend: 'sendMessage',
    }),
    createQuestion() {
      this.wsSend({
        type: 'FAQ',
        action: 'Create',
        question: this.newQuestion.question
      });
    },
    modifyQuestion() {
      this.wsSend({
        type: 'FAQ',
        action: 'Edit',
        id: this.editQuestion.id,
        question: this.editQuestion.question,
        answer: this.editQuestion.answer
      });
    },
    deleteSelectedQuestions() {
      for(const question of this.$refs.table.getVisibleAndSelectedRows()) {
        this.wsSend({
          type: 'FAQ',
          action: 'Delete',
          id: question.id
        });
      }
    },
  },
  created() {
    API.get('APIGateway', `/events/${this.$store.state.currentEvent}/state`, {})
    .then( (result) => {
      this.questions = result.faq.map(x => {
        return {
          id: x.id,
          ts: x.ts,
          question: x.question,
          answer: x.answer
        }
      }).sort((a, b) => a.ts < b.ts);
    });
  },
}
</script>

<style scoped>
.table thead th {
    vertical-align: top;
}
</style>
