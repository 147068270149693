<template>
  <b-col class="col-12 pt-3 pb-3">
    <b-card no-body align="center" class="h-100">
      <b-card-body class="d-flex flex-column">
        <h3><strong>Particle Effects</strong></h3>
        <div class="d-flex">
          <div class="d-inline-block w-50 pr-3">
            <b-form-group 
              class="w-100"
              label="Display Id:"
              label-cols="2"
              label-class="font-weight-bold"
              content-cols="10">
              <b-form-input v-model="particleEffects.displayId"></b-form-input>
            </b-form-group>
            <b-form-group 
              class="w-100"
              label="Button Id:"
              label-cols="2"
              label-class="font-weight-bold"
              content-cols="10">
              <b-form-input v-model="particleEffects.likeButtonId"></b-form-input>
            </b-form-group>
            <b-form-group
              class="w-100"
              label="Sprites:"
              label-cols="2"
              label-class="font-weight-bold"
              content-cols="10">
              <div>
                <b-container fluid>
                  <b-row v-for="(_, i) in particleEffects.sprites" :key="i">
                    <b-col class="col-9 pl-0 pr-3">
                      <b-form-input class="mb-3" v-model="particleEffects.sprites[i]"></b-form-input>
                    </b-col>
                    <b-col class="col-3 p-0">
                      <b-button class="w-100" @click="particleEffects.sprites = particleEffects.sprites.filter((_, j) => i != j)"> Remove </b-button>
                    </b-col>
                  </b-row>
                </b-container>                      
                <b-button class="w-100" @click="particleEffects.sprites.push('')">Add Sprite</b-button>
              </div>
            </b-form-group>
          </div>
          <div class="d-inline-block w-50 pl-3">
            <b-form-group 
              class="w-100"
              label="Drift Delay:"
              label-cols="2"
              label-class="font-weight-bold"
              content-cols="10">
              <b-container fluid>
                <b-row>
                  <b-col cols="10">
                    <b-form-input type="range" step="0.01" min="0" max="2" v-model.number="particleEffects.driftDelay"></b-form-input>
                  </b-col>
                  <b-col cols="2">
                    <b-form-input type="number" step="0.01" min="0" max="2" v-model.number="particleEffects.driftDelay"></b-form-input>
                  </b-col>
                </b-row>
              </b-container>
            </b-form-group>
            <b-form-group 
              class="w-100"
              label="Drift X:"
              label-cols="2"
              label-class="font-weight-bold"
              content-cols="10">
              <b-container fluid>
                <b-row>
                  <b-col cols="10">
                    <b-form-input type="range" step="0.0001" min="0" max="1" v-model.number="particleEffects.driftX"></b-form-input>
                  </b-col>
                  <b-col cols="2">
                    <b-form-input type="number" step="0.0001" min="0" max="1" v-model.number="particleEffects.driftX"></b-form-input>
                  </b-col>
                </b-row>
              </b-container>
            </b-form-group>
            <b-form-group 
              class="w-100"
              label="Drift Y:"
              label-cols="2"
              label-class="font-weight-bold"
              content-cols="10">
              <b-container fluid>
                <b-row>
                  <b-col cols="10">
                    <b-form-input type="range" step="0.0001" min="0" max="1" v-model.number="particleEffects.driftY"></b-form-input>
                  </b-col>
                  <b-col cols="2">
                    <b-form-input type="number" step="0.0001" min="0" max="1" v-model.number="particleEffects.driftY"></b-form-input>
                  </b-col>
                </b-row>
              </b-container>
            </b-form-group>
            <b-form-group 
              class="w-100"
              label="Start Alpha:"
              label-cols="2"
              label-class="font-weight-bold"
              content-cols="10">
              <b-container fluid>
                <b-row>
                  <b-col cols="10">
                    <b-form-input type="range" step="0.01" min="0" max="1" v-model.number="particleEffects.startAlpha"></b-form-input>
                  </b-col>
                  <b-col cols="2">
                    <b-form-input type="number" step="0.01" min="0" max="1" v-model.number="particleEffects.startAlpha"></b-form-input>
                  </b-col>
                </b-row>
              </b-container>
            </b-form-group>
            <b-form-group 
              class="w-100"
              label="End Alpha:"
              label-cols="2"
              label-class="font-weight-bold"
              content-cols="10">
              <b-container fluid>
                <b-row>
                  <b-col cols="10">
                    <b-form-input type="range" step="0.01" min="0" max="1" v-model.number="particleEffects.endAlpha"></b-form-input>
                  </b-col>
                  <b-col cols="2">
                    <b-form-input type="number" step="0.01" min="0" max="1" v-model.number="particleEffects.endAlpha"></b-form-input>
                  </b-col>
                </b-row>
              </b-container>
            </b-form-group>
            <b-form-group 
              class="w-100"
              label="Min Direction Angle:"
              label-cols="2"
              label-class="font-weight-bold"
              content-cols="10">
              <b-container fluid>
                <b-row>
                  <b-col cols="10">
                    <b-form-input type="range" step="0.1" min="-90" max="90" v-model.number="particleEffects.minDirectionAngle"></b-form-input>
                  </b-col>
                  <b-col cols="2">
                    <b-form-input type="number" step="0.1" min="-90" max="90" v-model.number="particleEffects.minDirectionAngle"></b-form-input>
                  </b-col>
                </b-row>
              </b-container>
            </b-form-group>
            <b-form-group 
              class="w-100"
              label="Max Direction Angle:"
              label-cols="2"
              label-class="font-weight-bold"
              content-cols="10">
              <b-container fluid>
                <b-row>
                  <b-col cols="10">
                    <b-form-input type="range" step="0.1" min="-90" max="90" v-model.number="particleEffects.maxDirectionAngle"></b-form-input>
                  </b-col>
                  <b-col cols="2">
                    <b-form-input type="number" step="0.1" min="-90" max="90" v-model.number="particleEffects.maxDirectionAngle"></b-form-input>
                  </b-col>
                </b-row>
              </b-container>
            </b-form-group>
            <b-form-group 
              class="w-100"
              label="Min Lifetime:"
              label-cols="2"
              label-class="font-weight-bold"
              content-cols="10">
              <b-container fluid>
                <b-row>
                  <b-col cols="10">
                    <b-form-input type="range" step="0.1" min="1" max="20" v-model.number="particleEffects.minLifetime"></b-form-input>
                  </b-col>
                  <b-col cols="2">
                    <b-form-input type="number" step="0.1" min="1" max="20" v-model.number="particleEffects.minLifetime"></b-form-input>
                  </b-col>
                </b-row>
              </b-container>
            </b-form-group>
            <b-form-group 
              class="w-100"
              label="Max Lifetime:"
              label-cols="2"
              label-class="font-weight-bold"
              content-cols="10">
              <b-container fluid>
                <b-row>
                  <b-col cols="10">
                    <b-form-input type="range" step="0.1" min="2" max="20" v-model.number="particleEffects.maxLifetime"></b-form-input>
                  </b-col>
                  <b-col cols="2">
                    <b-form-input type="number" step="0.1" min="2" max="20" v-model.number="particleEffects.maxLifetime"></b-form-input>
                  </b-col>
                </b-row>
              </b-container>
            </b-form-group>
            <b-form-group 
              class="w-100"
              label="Min Speed:"
              label-cols="2"
              label-class="font-weight-bold"
              content-cols="10">
              <b-container fluid>
                <b-row>
                  <b-col cols="10">
                    <b-form-input type="range" step="0.0001" min="0" max="1" v-model.number="particleEffects.minSpeed"></b-form-input>
                  </b-col>
                  <b-col cols="2">
                    <b-form-input type="number" step="0.0001" min="0" max="1" v-model.number="particleEffects.minSpeed"></b-form-input>
                  </b-col>
                </b-row>
              </b-container>
            </b-form-group>
            <b-form-group 
              class="w-100"
              label="Max Speed:"
              label-cols="2"
              label-class="font-weight-bold"
              content-cols="10">
              <b-container fluid>
                <b-row>
                  <b-col cols="10">
                    <b-form-input type="range" step="0.0001" min="0" max="1" v-model.number="particleEffects.maxSpeed"></b-form-input>
                  </b-col>
                  <b-col cols="2">
                    <b-form-input type="number" step="0.0001" min="0" max="1" v-model.number="particleEffects.maxSpeed"></b-form-input>
                  </b-col>
                </b-row>
              </b-container>
            </b-form-group>
            <b-form-group 
              class="w-100"
              label="Min Sprite Scale:"
              label-cols="2"
              label-class="font-weight-bold"
              content-cols="10">
              <b-container fluid>
                <b-row>
                  <b-col cols="10">
                    <b-form-input type="range" step="0.0001" min="0" max="1" v-model.number="particleEffects.minSpriteScale"></b-form-input>
                  </b-col>
                  <b-col cols="2">
                    <b-form-input type="number" step="0.0001" min="0" max="1" v-model.number="particleEffects.minSpriteScale"></b-form-input>
                  </b-col>
                </b-row>
              </b-container>
            </b-form-group>
            <b-form-group 
              class="w-100"
              label="Max Sprite Scale:"
              label-cols="2"
              label-class="font-weight-bold"
              content-cols="10">
              <b-container fluid>
                <b-row>
                  <b-col cols="10">
                    <b-form-input type="range" step="0.0001" min="0" max="1" v-model.number="particleEffects.maxSpriteScale"></b-form-input>
                  </b-col>
                  <b-col cols="2">
                    <b-form-input type="number" step="0.0001" min="0" max="1" v-model.number="particleEffects.maxSpriteScale"></b-form-input>
                  </b-col>
                </b-row>
              </b-container>
            </b-form-group>
            <b-form-group 
              class="w-100"
              label="Origin X:"
              label-cols="2"
              label-class="font-weight-bold"
              content-cols="10">
              <b-container fluid>
                <b-row>
                  <b-col cols="10">
                    <b-form-input type="range" step="0.0001" min="0" max="1" v-model.number="particleEffects.originX"></b-form-input>
                  </b-col>
                  <b-col cols="2">
                    <b-form-input type="number" step="0.0001" min="0" max="1" v-model.number="particleEffects.originX"></b-form-input>
                  </b-col>
                </b-row>
              </b-container>
            </b-form-group>
            <b-form-group 
              class="w-100"
              label="Origin Y:"
              label-cols="2"
              label-class="font-weight-bold"
              content-cols="10">
              <b-container fluid>
                <b-row>
                  <b-col cols="10">
                    <b-form-input type="range" step="0.0001" min="0" max="1" v-model.number="particleEffects.originY"></b-form-input>
                  </b-col>
                  <b-col cols="2">
                    <b-form-input type="number" step="0.0001" min="0" max="1" v-model.number="particleEffects.originY"></b-form-input>
                  </b-col>
                </b-row>
              </b-container>
            </b-form-group>
            <b-form-group 
              class="w-100"
              label="Sprite Count:"
              label-cols="2"
              label-class="font-weight-bold"
              content-cols="10">
              <b-container fluid>
                <b-row>
                  <b-col cols="10">
                    <b-form-input type="range" step="1" min="1" max="20" v-model.number="particleEffects.spriteCount"></b-form-input>
                  </b-col>
                  <b-col cols="2">
                    <b-form-input type="number" step="1" min="1" max="20" v-model.number="particleEffects.spriteCount"></b-form-input>
                  </b-col>
                </b-row>
              </b-container>
            </b-form-group>
          </div>
        </div>
        <b-button class="align-self-end mt-auto" variant="success" @click="save()">Save</b-button>
      </b-card-body>
    </b-card>
  </b-col>
</template>
<script>
import { API } from 'aws-amplify';
import stringify from 'json-stable-stringify';

export default {
  props: {
    event: {
      type: Object,
    },
  },
  data() {
    return {
      particleEffects: null,
    }
  },
  methods: {
    load(initial=false) {
      if (!initial && this.isDirty()) {
        return;
      }
      if(this.event.has_particle_effects && this.event.particle_effects) {
        this.particleEffects = JSON.parse(JSON.stringify(this.event.particle_effects));
      } else {
        this.particleEffects = {
          displayId: "",
          likeButtonId: "",
          sprites: [],
          driftDelay: 0.05,
          driftX: 0.015,
          driftY: 0.02,
          startAlpha: 1.0,
          endAlpha: 0.2,
          maxDirectionAngle: -10,
          minDirectionAngle: -80,
          maxLifetime: 4,
          minLifetime: 2,
          maxSpeed: 0.006,
          minSpeed: 0.004,
          maxSpriteScale: 0.06,
          minSpriteScale: 0.03,
          originX: 1.0,
          originY: 1.0,
          spriteCount: 1
        }
      }
    },
    isDirty() {
      if(this.event.has_particle_effects && this.event.particle_effects) {
        return stringify(this.event.particle_effects) !== stringify(this.particleEffects);
      } else {
        return stringify({
          displayId: "",
          likeButtonId: "",
          sprites: [],
          driftDelay: 0.05,
          driftX: 0.015,
          driftY: 0.02,
          startAlpha: 1.0,
          endAlpha: 0.2,
          maxDirectionAngle: -10,
          minDirectionAngle: -80,
          maxLifetime: 4,
          minLifetime: 2,
          maxSpeed: 0.006,
          minSpeed: 0.004,
          maxSpriteScale: 0.06,
          minSpriteScale: 0.03,
          originX: 1.0,
          originY: 1.0,
          spriteCount: 1
        }) !== stringify(this.particleEffects);
      }
    },
    discard() {
      this.load(true);
    },
    async save() {
      await API.put('APIGateway', `/events/${this.$store.state.currentEvent}`, { body: {
        particle_effects: this.particleEffects
      }})
      .then( response => {
        this.particleEffects = response.particle_effects;

        this.$bvToast.toast(`Successfully updated particle effects settings.`, {
          title: `Success!`,
          toaster: "b-toaster-bottom-right",
          variant: "success",
          solid: true
        });
      })
      .catch( e => {
        this.$bvToast.toast(`Failed to update particle effects settings.`, {
          title: `Error!`,
          toaster: "b-toaster-bottom-right",
          variant: "error",
          solid: true
        });
      });
    },
  },
  watch: {
    event() {
      this.load();
    },
  },
  created() {
    this.load(true);
  }
};
</script>
<style scoped>
</style>