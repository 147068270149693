<template>
  <div>
    <b-modal id="delete-admin-modal" title="Delete Admin" hide-footer>
      <p>Are you sure you want to delete ALL selected admins?</p>
      <b-button class="mr-1" variant="danger" @click="deleteSelectedAdmins(); $bvModal.hide('delete-admin-modal')">Confirm</b-button>
      <b-button @click="$bvModal.hide('delete-admin-modal')">Cancel</b-button>
    </b-modal>
    <b-modal @show="group=''" id="add-group-modal" title="Add Group" hide-footer>
      <p>Select the group you want to add the selected admins to.</p>
      <b-form-select class="mb-3" v-model="group" :options="groupOptions"></b-form-select>
      <b-button class="mr-1" :disabled="!group" @click="addGroupToSelectedAdmins(group);group=''; $bvModal.hide('add-group-modal')">Add</b-button>
      <b-button @click="$bvModal.hide('add-group-modal')">Cancel</b-button>
    </b-modal>
    <b-modal @show="group=''" id="remove-group-modal" title="Remove Group" hide-footer>
      <p>Select the group you want to remove the selected admins from.</p>
      <b-form-select class="mb-3" v-model="group" :options="groupOptions"></b-form-select>
      <b-button class="mr-1" :disabled="!group" @click="removeGroupFromSelectedAdmins(group); $bvModal.hide('remove-group-modal')">Remove</b-button>
      <b-button @click="$bvModal.hide('remove-group-modal')">Cancel</b-button>
    </b-modal>
    <b-modal @show="group=''; email=''; password=''" id="create-admin-modal" title="Create Admin" hide-footer>
      <p>Enter Email:</p>
      <b-form-input
        class="mb-3"
        id="email"
        v-model="email"
        type="email"
        placeholder="Enter Email"
      ></b-form-input>
      <p>Enter Password:</p>
      <b-form-input
        class="mb-3"
        id="password"
        v-model="password"
        placeholder="Enter Password"
      ></b-form-input>
      <p>Select Group:</p>
      <b-form-select class="mb-3" v-model="group" :options="groupOptions"></b-form-select>
      <b-button class="mr-1" :disabled="!group||!email||!password" @click="createAdmin(email, password, group); $bvModal.hide('create-admin-modal')">Create</b-button>
      <b-button @click="$bvModal.hide('create-admin-modal')">Cancel</b-button>
    </b-modal>
    <Table ref="table" :rows="rows" :columns="columns" :actions="actions"></Table>
  </div>
</template>

<script>
import { API, Auth } from 'aws-amplify'

export default {
  data() {
    return {
      admins: [],
      group: "",
      events: [],
      username: "",
      email: "",
      password: ""
    }
  },
  computed: {
    rows() {
      return this.admins;
    },
    columns() {
      const result = [];
      result.push({
        name: "Email",
        value: (row) => row.email
      });
      result.push({
        name: "Groups",
        value: (row) => row.groups.join(", ")
      });
      return result;
    },
    actions() {
      const result = [];
      result.push({
        name: "Create Admin",
        action: () => this.$bvModal.show('create-admin-modal'),
      });
      result.push({
        name: "Add Group",
        action: () => this.$bvModal.show('add-group-modal'),
        someSelected: true,
      });
      result.push({
        name: "Remove Group",
        action: () => this.$bvModal.show('remove-group-modal'),
        someSelected: true,
      });
      result.push({
        name: "Delete Admin",
        action: () => this.$bvModal.show('delete-admin-modal'),
        dangerous: true,
        someSelected: true,
      });
      return result;
    },
    groupOptions() {
      const options = [{
        value: "Administrator",
        text: "Administrator"
      }];

      return options.concat(this.events.map(x => {
        return {
          value: x.id,
          text: `${x.id} (${x.event_name})`
        }
      }));
    },
  },
  methods: {
    deleteSelectedAdmins() {
      for(const admin of this.$refs.table.getVisibleAndSelectedRows()) {
        API.del('APIGateway', `/admin/${admin.username}`, {})
        .then((result) => {
          this.admins = this.admins.filter(x => x.username != admin.username);
        })
        .catch((e) => {
          console.log(e)
        })
      }
    },
    addGroupToSelectedAdmins(group) {
      for(const admin of this.$refs.table.getVisibleAndSelectedRows()) {
        API.put('APIGateway', `/admin/${admin.username}`, {
          body: {
            groups: admin.groups.concat([group])
          }
        })
        .then((result) => {
          admin.groups = result.groups;
        })
        .catch((e) => {
          console.log(e)
        })
      }
    },
    removeGroupFromSelectedAdmins(group) {
      for(const admin of this.$refs.table.getVisibleAndSelectedRows()) {
        API.put('APIGateway', `/admin/${admin.username}`, {
          body: {
            groups: admin.groups.filter(x => x != group)
          }
        })
        .then((result) => {
          admin.groups = result.groups;
        })
        .catch((e) => {
          console.log(e)
        })
      }
    },
    createAdmin(email, password, group) {
      API.post('APIGateway', `/admin`, {
        body: {
          username: email,
          email: email,
          password: password,
          groups: [group]
        }
      })
      .then((result) => {
        this.admins.push({
          username: result.username,
          email: result.email,
          groups: result.groups,
        });
      })
      .catch((e) => {
        console.log(e)
      })
    }
  },
  created() {
    this.$store.commit("setCurrentEvent", "");

    API.get('APIGateway', `/admin`, {})
    .then((result) => {
      this.admins = result.admins.map(x => {
        return {
          username: x.username,
          email: x.email,
          groups: x.groups,
        }
      });
    })
    .catch((e) => {
      console.log(e)
    });

    API.get('APIGateway', '/events', {})
    .then((result) => {
      this.events = result.events.map(x => {
        return {
          id: x.id,
          event_name: x.event_name
        }
      });
    })
    .catch(e => console.error(e));
  }
}
</script>

<style scoped>
.table thead th {
    vertical-align: top;
}
</style>
