<template>
  <div class="menubar mb-3">
    <b-navbar toggleable="lg" type="dark" variant="primary">
      <b-navbar-brand href="#" :to="{ name: 'home'}">B-HYBRID</b-navbar-brand>
      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav v-if="$store.state.currentEvent">
          <router-link class="nav-item" :class="{'active-route': $route.name === 'event-guest-list' }" :to="{ name: 'event-guest-list' }"> Guest List </router-link>
        </b-navbar-nav>
        <b-navbar-nav v-if="$store.state.currentEvent && $store.state.groups.includes('Administrator')">
          <router-link class="nav-item" :class="{'active-route': $route.name === 'event-dynamic-content' }" :to="{ name: 'event-dynamic-content' }"> Backend-triggered Content </router-link>
        </b-navbar-nav>
        <b-navbar-nav v-if="$store.state.currentEvent">
          <router-link class="nav-item" :class="{'active-route': $route.name === 'event-questions' }" :to="{ name: 'event-questions' }"> Questions </router-link>
        </b-navbar-nav>
        <b-navbar-nav v-if="$store.state.currentEvent">
          <router-link class="nav-item" :class="{'active-route': $route.name === 'event-chat' }" :to="{ name: 'event-chat' }"> Chat </router-link>
        </b-navbar-nav>
        <b-navbar-nav v-if="$store.state.currentEvent">
          <router-link class="nav-item" :class="{'active-route': $route.name === 'event-particle-effects' }" :to="{ name: 'event-particle-effects' }"> Particle Effects </router-link>
        </b-navbar-nav>
        <b-navbar-nav v-if="$store.state.currentEvent && $store.state.groups.includes('Administrator')">
          <router-link class="nav-item" :class="{'active-route': $route.name === 'event-posts' }" :to="{ name: 'event-posts' }"> Posts </router-link>
        </b-navbar-nav>
        <b-navbar-nav v-if="$store.state.currentEvent && $store.state.groups.includes('Administrator')">
          <router-link class="nav-item" :class="{'active-route': $route.name === 'event-translations' }" :to="{ name: 'event-translations' }"> Translations </router-link>
        </b-navbar-nav>
        <b-navbar-nav v-if="$store.state.currentEvent && $store.state.groups.includes('Administrator')">
          <router-link class="nav-item" :class="{'active-route': $route.name === 'event-settings' }" :to="{ name: 'event-settings' }"> Settings </router-link>
        </b-navbar-nav>
        <b-navbar-nav v-if="!$store.state.currentEvent">
          <router-link class="nav-item" :class="{'active-route': $route.name === 'events' || $route.name === 'home' }" :to="{ name: 'events' }"> Events </router-link>
        </b-navbar-nav>
        <b-navbar-nav v-if="!$store.state.currentEvent && $store.state.groups.includes('Administrator')">
          <router-link class="nav-item" :class="{'active-route': $route.name === 'admins' }" :to="{ name: 'admins' }"> Admins </router-link>
        </b-navbar-nav>

        <!-- Right aligned nav items -->
        <b-navbar-nav class="ml-auto">
          <b-nav-item-dropdown right>
            <!-- Using 'button-content' slot -->
            <template #button-content>
              <em>Logged in as {{ $store.state.username }}</em>
            </template>
            <b-dropdown-item>
              <amplify-sign-out></amplify-sign-out>
            </b-dropdown-item>
          </b-nav-item-dropdown>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
  </div>
</template>

<script>
import Vue from 'vue'

export default {
  methods: {
  }
}
</script>

<style scoped>
.nav-item {
  color: white;
  margin-right: 15px;
  cursor: pointer;
}

.active-route {
  font-size: 20px;
  font-weight: bolder;
}

.routes-container {
  display: flex;
}
</style>
