<template>
  <div>
    <b-modal id="delete-message-modal" title="Delete Message" hide-footer>
      <p>Are you sure you want to delete ALL selected messages?</p>
      <b-button class="mr-1" variant="danger" @click="deleteSelectedMessages(); $bvModal.hide('delete-message-modal')">Confirm</b-button>
      <b-button @click="$bvModal.hide('delete-message-modal')">Cancel</b-button>
    </b-modal>
    <Table ref="table" :columns="columns" :rows="rows" :actions="actions"></Table>
  </div>
</template>

<script>
import { API } from 'aws-amplify';
import { mapActions, mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters({
      latestMessage: 'getLatestMessage',
    }),
    columns() {
      const result = [];
      result.push({
        name: "Timestamp",
        value: (row) => row.ts
      });
      result.push({
        name: "Message",
        value: (row) => row.message
      });
      return result;
    },
    rows() {
      return this.messages;
    },
    actions() {
      const result = [];
      result.push({
        name: "Delete Messages",
        action: () => this.$bvModal.show('delete-message-modal'),
        dangerous: true,
        someSelected: true,
      });
      return result;
    },
  },
  data() {
    return {
      messages: [],
    }
  },
  watch: {
    latestMessage: {
      handler() {
        const data = this.latestMessage;

        if (data.type !== 'LiveChat') {
          return;
        }

        if(data.action === "Create") {
          this.messages.unshift({
            name: data.name,
            message: data.message,
            id: data.id,
            ts: data.ts,
          });
        } else if(data.action === "Edit") {
          const message = this.messages.find(x => x.id === data.id);
          message.name = data.name;
          message.message = data.message;
        } else if(data.action === "Delete") {
          this.messages = this.messages.filter(x => x.id != data.id);
        }
      },
    },
  },
  methods: {
    ...mapActions({
      wsSend: 'sendMessage'
    }),
    deleteSelectedMessages() {
      for(const message of this.$refs.table.getVisibleAndSelectedRows()) {
        this.wsSend({
          type: 'LiveChat',
          action: 'Delete',
          id: message.id
        });
      }
    },
  },
  created() {
    API.get('APIGateway', `/events/${this.$store.state.currentEvent}/state`, {})
    .then( (result) => {
      this.messages = result.live_chat.map(x => {
        return {
          id: x.id,
          ts: x.ts,
          name: x.name,
          message: x.message
        }
      }).sort((a, b) => a.ts < b.ts);
    });
  },
}
</script>
<style scoped>
.table thead th {
    vertical-align: top;
}
</style>