<template>
  <b-col class="col-6 pt-3 pb-3">
    <b-card no-body class="h-100">
      <b-card-body class="d-flex flex-column align-items-center">
        <h3><strong>General<Tooltip name="demo"/></strong></h3>
        <b-form-group 
        class="w-100"
        label="Id:"
        label-cols="2"
        label-class="font-weight-bold"
        content-cols="10"
        >
          <b-form-input
              :disabled="true"
              v-model="id"
          ></b-form-input>
        </b-form-group>
        <b-form-group 
        class="w-100"
        label="Name:"
        label-cols="2"
        label-class="font-weight-bold"
        content-cols="10"
        >
          <b-form-input
              v-model="name"
          ></b-form-input>
        </b-form-group>
        <b-form-group 
        class="w-100"
        label="URL:"
        label-cols="2"
        label-class="font-weight-bold"
        content-cols="10"
        >
          <b-container fluid>
            <b-row class="mb-3">
              <b-col class="col-8 pl-0 pr-3">
                <b-form-input
                  v-model="url"
                ></b-form-input>
              </b-col>
              <b-col class="col-4 p-0">
                <b-button class="w-100" variant="success" @click="updateCDN()"> Update CDN </b-button>
              </b-col>
            </b-row>
          </b-container>  
        </b-form-group>
        <b-form-group 
        class="w-100"
        label="Email:"
        label-cols="2"
        label-class="font-weight-bold"
        content-cols="10"
        >
          <b-form-input
              v-model="email"
          ></b-form-input>
        </b-form-group>
        <b-form-group 
        class="w-100"
        label="Features:"
        label-cols="2"
        label-class="font-weight-bold"
        content-cols="10"
        >
          <div>
            <b-form-checkbox class="text-left" v-model="hasDynamicContent">Backend-triggered Content</b-form-checkbox>
            <b-form-checkbox class="text-left" v-model="hasParticleEffects">Particle Effects</b-form-checkbox>
            <b-form-checkbox class="text-left" v-model="hasLiveChat">Live Chat</b-form-checkbox>
            <b-form-checkbox class="text-left" v-model="hasFAQ">Q&A</b-form-checkbox>
            <b-form-checkbox class="text-left" v-model="hasConfirm">Confirm</b-form-checkbox>
            <b-form-checkbox class="text-left" v-model="hasSurvey">Survey</b-form-checkbox>
          </div>
        </b-form-group>
        <b-button class="align-self-end mt-auto" variant="success" @click="save()">Save</b-button>
      </b-card-body>
    </b-card>
  </b-col>
</template>
<script>
import { API } from 'aws-amplify';

export default {
  props: {
    event: {
      type: Object,
    },
  },
  data() {
    return {
      id: "",
      name: "",
      email: "",
      url: "",
      hasDynamicContent: false,
      hasLiveChat: false,
      hasFAQ: false,
      hasSurvey: false,
      hasParticleEffects: false,
      hasConfirm: false,
    }
  },
  methods: {
    load(initial=false) {
      if (!initial && this.isDirty()) {
        console.log("skip loading");
        return;
      }
      this.id = JSON.parse(JSON.stringify(this.event.id));
      this.email = JSON.parse(JSON.stringify(this.event.email));
      this.name = JSON.parse(JSON.stringify(this.event.name));
      this.url = JSON.parse(JSON.stringify(this.event.url));

      this.hasAuth = this.event.has_auth;
      this.hasParticleEffects = this.event.has_particle_effects;
      this.hasDynamicContent = this.event.has_dynamic_content;
      this.hasLiveChat = this.event.has_live_chat;
      this.hasFAQ = this.event.has_faq;
      this.hasConfirm = this.event.has_confirm;
      this.hasSurvey = this.event.has_survey;
    },
    isDirty() {
      return this.id !== this.event.id || this.email !== this.event.email || this.name !== this.event.name ||
        this.url !== this.event.url || this.hasAuth !== this.event.has_auth || this.hasParticleEffects !== this.event.has_particle_effects ||
        this.hasDynamicContent !== this.event.has_dynamic_content || this.hasLiveChat !== this.event.has_live_chat ||
        this.hasLiveChat !== this.event.has_live_chat || this.hasConfirm !== this.event.has_confirm ||
        this.hasSurvey !== this.event.has_survey;
    },
    discard() {
      this.load(true);
    },
    async save() {
      await API.put('APIGateway', `/events/${this.$store.state.currentEvent}`, { body: {
        name: this.name,
        email: this.email,
        url: this.url,
        has_dynamic_content: this.hasDynamicContent,
        has_live_chat: this.hasLiveChat,
        has_faq: this.hasFAQ,
        has_particle_effects: this.hasParticleEffects,
        has_confirm: this.hasConfirm,
        has_survey: this.hasSurvey,
      }})
      .then( response => {
        this.$emit("update:event", response);

        this.$bvToast.toast(`Successfully updated general settings.`, {
          title: `Success!`,
          toaster: "b-toaster-bottom-right",
          variant: "success",
          solid: true
        });
      })
      .catch( e => {
        this.$bvToast.toast(`Failed to update general settings due to: ${e.response.data.Message}`, {
          title: `Error!`,
          autoHideDelay: 20000,
          toaster: "b-toaster-bottom-right",
          variant: "error",
          solid: true
        });
      });
    },
    async updateCDN() {
      await this.save();

      await API.post('APIGateway', `/events/${this.$store.state.currentEvent}/invalidate`, { body: {}})
      .then( _ => {
        this.$bvToast.toast(`Successfully invalidated CDN.`, {
          title: `Success!`,
          toaster: "b-toaster-bottom-right",
          variant: "success",
          solid: true
        });
      })
      .catch( e => {
        this.$bvToast.toast(`Failed to invalidate CDN due to ${e.response.data.Message}`, {
          title: `Error!`,
          autoHideDelay: 20000,
          toaster: "b-toaster-bottom-right",
          variant: "error",
          solid: true
        });
      });

    }
  },
  watch: {
    event() {
      this.load();
    },
  },
  created() {
    this.load(true);
  }
}
</script>
<style scoped>

</style>