<template>
  <b-container fluid>
    <b-row>
      <b-col sm="12" md="3" lg="2" class="mb-3">
        <b-dropdown id="actionDropdown" menu-class="w-100" text="Actions" class="w-100 mb-1">
          <b-dropdown-item-button v-bind:disabled="allSelected" @click="selectAll()">Select All</b-dropdown-item-button>
          <b-dropdown-item-button v-bind:disabled="!someSelected" @click="unselectAll()">Unselect All</b-dropdown-item-button>
          <b-dropdown-divider v-if="safeActions.length > 0"></b-dropdown-divider>
          <b-dropdown-item-button v-for="(action, idx) in safeActions" :key="'safe-action-'+idx" v-bind:disabled="!((action.someSelected ? someSelected : true) && (action.oneSelected ? oneSelected : true) && (action.allSelected ? allSelected : true))" @click="action.action">{{action.name}}</b-dropdown-item-button>
          <b-dropdown-divider v-if="dangerousActions.length > 0"></b-dropdown-divider>
          <b-dropdown-item-button variant="danger" v-for="(action, idx) in dangerousActions" :key="'dangerous-action-'+idx" v-bind:disabled="!((action.someSelected ? someSelected : true) && (action.oneSelected ? oneSelected : true) && (action.allSelected ? allSelected : true))" @click="action.action">{{action.name}}</b-dropdown-item-button>
        </b-dropdown>
      </b-col>
      <slot v-if="$slots.header" name="header" class="mb-3"/>
      <b-col class="mb-3">
        <input
          v-model="filter"
          placeholder="Search..."
          class="form-control w-100 mb-1"
          type="text"
        />
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12">
        <table class="table">
          <thead>
            <tr>
              <th>
                <div class="form-check">
                <input
                    class="form-check-input"
                    type="checkbox"
                    v-bind:checked="allSelected && someSelected"
                    @click="$event.target.checked ? selectAll() : unselectAll()"
                />
                </div>
              </th>
              <th v-for="(column, idx) in columns" :key="idx" style="cursor: pointer" @click="sortAscending = sortColumn == idx ? !sortAscending : true; sortColumn = idx;">
                <a v-if="sortColumn == idx && !sortAscending">{{column.name}} &#8593;</a>
                <a v-else-if="sortColumn == idx && sortAscending">{{column.name}} &#8595;</a>
                <a v-else>{{column.name}}</a>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr @click="selectedRows.includes(row) ? unselectRow(row) : selectRow(row)" @mouseover="howerRow=rowIdx" @mouseleave="howerRow=null" :class="{'bg-secondary': howerRow === rowIdx }" v-for="(row, rowIdx) in sortedRows" :key="rowIdx">
              <td>
                <div class="form-check">
                <input
                  class="form-check-input"
                  type="checkbox"
                  v-bind:checked="selectedRows.includes(row)"
                />
                </div>
              </td>
              <td v-for="(column, columnIdx) in columns" :key=columnIdx> {{ column.value(row) }} </td>
            </tr>
          </tbody>
        </table>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import stringify from 'csv-stringify/lib/sync';

export default {
  props: {
    columns: {
      type: Array,
      required: true,
    },
    rows: {
      type: Array,
      required: true,
    },
    actions: {
      type: Array,
      required: true,
    },
    filters: {
      type: Array,
      default: () => []
    },
  },
  data() {
    return {
      howerRow: null,
      filter: null,
      sortColumn: 0,
      sortAscending: true,
      selectedRows: [],
    };
  },
  computed: {
    dangerousActions() {
      return this.actions.filter((x) => !!x.dangerous );
    },
    safeActions() {
      return this.actions.filter((x) => !x.dangerous );
    },
    oneSelected() {
      let count = 0;
      for (const row of this.filteredRows) {
        if (this.selectedRows.includes(row)) {
          count += 1;
        }
      }

      return count === 1;
    },
    someSelected() {
      let count = 0;
      for (const row of this.filteredRows) {
        if (this.selectedRows.includes(row)) {
          count += 1;
        }
      }

      return count > 0;
    },
    allSelected() { 
      let count = 0;
      for (const row of this.filteredRows) {
        if (this.selectedRows.includes(row)) {
          count += 1;
        }
      }

      return count === this.filteredRows.length;
    },
    filteredRows() {
      let result = this.rows;

      if(this.filter) {
        const regex = new RegExp(this.filter, "i");
        result = result.filter(row => {
          for (const column of this.columns) {
            if (regex.test(column.value(row))) {
              return true;
            }
          }
          return false;
        });
      }

      if(this.filters) {
        for(const filter of this.filters) {
          result = result.filter(filter);
        }
      }

      return result;
    },
    sortedRows() {
      const result = [...this.filteredRows];
      result.sort((lhs, rhs) => {
        const column = this.columns[this.sortColumn];

        const lhsValue = column.value(lhs);
        const rhsValue = column.value(rhs);

        if (lhsValue < rhsValue) {
          return -1;
        } else if (lhsValue > rhsValue) {
          return 1;
        }

        return 0;
      });

      if (!this.sortAscending) {
        result.reverse();
      }

      return result;
    },
  },
  watch: {
    rows: {
      deep: true,
      handler() {
        this.selectedRows = this.selectedRows.filter((x) => this.rows.includes(x));
      },
    },
  },
  methods: {
    flatten(obj) {
      return this.flattenInternal(obj, "");
    },
    flattenInternal(obj, prefix) {
      let result = {};
      if (typeof obj === 'object') {
        for (const [key, value] of Object.entries(obj)) {
          const nextPrefix = prefix.length > 0 ? `${prefix}.${key}` : `${key}`;
          result = {...result, ...this.flattenInternal(value, nextPrefix)};
        }
      } else {
        result[prefix] = obj;
      }
      return result;
    },
    exportRowsCSV(filename="table.csv") {
      const flatRows = this.rows.map((x) => this.flatten(x));

      const columns = flatRows
        .map(x => Object.keys(x))
        .reduce((acc, x) => ([...acc, ...x]), [])
        .filter((x, index, self) => self.indexOf(x) === index);

      const data = stringify(flatRows, {
        header: true,
        columns,
        cast: {
          string: x => x.trim(),
        },
      });

      const blob = new Blob([data], {type: 'text/csv'})
      const e = document.createEvent('MouseEvents'),
      a = document.createElement('a');
      a.download = filename;
      a.href = window.URL.createObjectURL(blob);
      a.dataset.downloadurl = ['text/csv', a.download, a.href].join(':');
      e.initEvent('click', true, false, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null);
      a.dispatchEvent(e);
    },
    exportRowsJSON(filename="table.json") {
      const data = JSON.stringify(this.rows);

      const blob = new Blob([data], {type: 'application/json'})
      const e = document.createEvent('MouseEvents'),
      a = document.createElement('a');
      a.download = filename;
      a.href = window.URL.createObjectURL(blob);
      a.dataset.downloadurl = ['application/json', a.download, a.href].join(':');
      e.initEvent('click', true, false, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null);
      a.dispatchEvent(e);
    },
    getVisibleAndSelectedRows() {
      return this.filteredRows.filter((x) => this.selectedRows.includes(x) );
    },
    selectAll() {
      this.selectedRows = [...this.filteredRows];
    },
    unselectAll() {
      this.selectedRows = [];
    },
    selectRow(row) {
      this.selectedRows.push(row);
    },
    unselectRow(row) {
      this.selectedRows = this.selectedRows.filter(x => x !== row);
    }
  }
};
</script>
<style scoped>
  .table thead th {
    vertical-align: top;
  }
</style>
