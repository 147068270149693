<template>
  <div>
    <b-modal id="reset-dynamic-content-modal" title="Reset Backend-triggered Content" hide-footer>
      <p>Are you sure you want to reset ALL selected backend-triggered Content?</p>
      <b-button class="mr-3" variant="danger" @click="resetSelectedDynamicContent(); $bvModal.hide('reset-dynamic-content-modal')">Confirm</b-button>
      <b-button @click="$bvModal.hide('reset-dynamic-content-modal')">Cancel</b-button>
    </b-modal>
    <b-modal @show="commands=tempCommands" size="xl" id="execute-trigger-modal" title="Execute Trigger" hide-footer>
      <div class="d-flex flex-column">
        <b-form-group 
          class="w-100"
          label="Trigger:"
          label-cols="2"
          label-class="font-weight-bold"
          content-cols="10">
          <b-form-select v-model="commands" :options="triggerOptions"></b-form-select>
        </b-form-group>
        <b-form-group 
          class="w-100"
          label="Commands:"
          label-cols="2"
          label-class="font-weight-bold"
          content-cols="10">
          <div class="mb-3" v-for="(command, i) of commands" :key="i">
            <b-container fluid>
              <b-row>
                <div class="col-9 pl-0 pr-2">
                  <b-button class="w-100" v-b-toggle="'command-edit'+i">Command {{i+1}}</b-button>
                </div>
                <div class="col-3 p-0">
                  <b-button class="w-100" @click="commands.splice(i, 1)"> Remove </b-button>
                </div>
              </b-row>
            </b-container>
            <b-collapse :id="'command-edit'+i" accordion="edit-command-accordion" class="mt-2">
              <b-card>
                <b-form-group 
                  class="w-100"
                  label="Element ID:"
                  label-cols="2"
                  label-class="font-weight-bold"
                  content-cols="10">
                  <b-form-input
                    v-model="command.elementId"
                    placeholder="Enter Element ID"
                  ></b-form-input>
                </b-form-group>
                <b-form-group 
                  class="w-100"
                  label="Remove Classes:"
                  label-cols="2"
                  label-class="font-weight-bold"
                  content-cols="10">
                  <b-form-input
                    v-model="command.removeClasses"
                    placeholder="Enter Classes to be removed(space separated)"
                  ></b-form-input>
                </b-form-group>
                <b-form-group 
                  class="w-100"
                  label="Add Classes:"
                  label-cols="2"
                  label-class="font-weight-bold"
                  content-cols="10">
                  <b-form-input
                    v-model="command.addClasses"
                    placeholder="Enter Classes to be added(space separated)"
                  ></b-form-input>
                </b-form-group>
                <b-form-group 
                  class="w-100"
                  label="Source:"
                  label-cols="2"
                  label-class="font-weight-bold"
                  content-cols="10">
                  <b-form-input
                    v-model="command.source"
                    placeholder="Enter Source"
                  ></b-form-input>
                </b-form-group>
                <b-form-group 
                  class="w-100"
                  label="Delay:"
                  label-cols="2"
                  label-class="font-weight-bold"
                  content-cols="10">
                  <b-form-input
                    step="0.1"
                    v-model.number="command.delay"
                    type="number"
                    placeholder="Enter Delay after command"
                  ></b-form-input>
                </b-form-group>
              </b-card>
            </b-collapse>
          </div>
          <b-button class="w-100 mb-3" @click="commands.push({elementId: '', removeClasses: '', addClasses: '', source: '', delay: 0})">Add Command</b-button>
        </b-form-group>
        <div class="align-self-end mt-auto">
          <b-button class="mr-3" @click="executeCommands(commands); $bvModal.hide('execute-trigger-modal')">Execute</b-button>
          <b-button @click="$bvModal.hide('execute-trigger-modal')">Cancel</b-button>
        </div>
      </div>
    </b-modal>
    <Table :columns="columns" :rows="rows" :actions="actions" ref="table"></Table>
  </div>
</template>

<script>
import { API } from 'aws-amplify';
import { mapActions, mapGetters } from 'vuex';

export default {
  data() {
    return {      
      dynamicContents: [],
      triggers: [],
      tempCommands: [],
      commands: [],
      currentTrigger: null,
    }
  },
  computed: {
    ...mapGetters({
      latestMessage: 'getLatestMessage',
    }),
    columns() {
      const result = [];
      result.push({
        name: "Element ID",
        value: (row) => row.elementId
      });
      result.push({
        name: "Source",
        value: (row) => row.source
      });
      result.push({
        name: "Current Classes",
        value: (row) => row.currentClasses.join(" ")
      });
      result.push({
        name: "Removed Classes",
        value: (row) => row.removedClasses.join(" ")
      });
      return result;
    },
    rows() {
      return this.dynamicContents;
    },
    actions() {
      const result = [];
      result.push({
        name: "Execute Trigger",
        action: () => this.$bvModal.show('execute-trigger-modal'),
      });
      result.push({
        name: "Reset",
        action: () => this.$bvModal.show('reset-dynamic-content-modal'),
        dangerous: true,
        someSelected: true,
      });
      return result;
    },
    triggerOptions() {
      return [
        {
          value: this.tempCommands,
          text: "Temporary Commands"
        },
        ...this.triggers.map(x => {
          return {
            value: x.commands,
            text: x.name
          }
        })
      ];
    },
  },
  watch: {
    latestMessage: {
      handler() {
        const data = this.latestMessage;
        if(data.type == "DynamicContent") {
          if(data.action == "Trigger") {        
            const commands = this.decodeCommands(data.commands);
            for(const command of commands) {
              if (!command.elementId) {
                continue;
              }

              let dynamicContent = this.dynamicContents.find(x => x.elementId == command.elementId);
              if(!dynamicContent) {
                dynamicContent = {
                  elementId: command.elementId,
                  removedClasses: [],
                  currentClasses: [],
                  source: "",
                };
                this.dynamicContents.push(dynamicContent);
              }
              
              if (command.removeClasses) {
                  dynamicContent.removedClasses = dynamicContent.removedClasses.concat(command.removeClasses);
                  dynamicContent.removedClasses = Array.from(new Set(dynamicContent.removedClasses));
                  dynamicContent.currentClasses = dynamicContent.currentClasses.filter(x => !(command.removeClasses.includes(x)));
              }

              if (command.addClasses) {
                  dynamicContent.currentClasses = dynamicContent.currentClasses.concat(command.addClasses);
                  dynamicContent.currentClasses = Array.from(new Set(dynamicContent.currentClasses));
              }

              if (command.source) {
                  dynamicContent.source = command.source;
              }
            }
          } else if(data.action == "Reset") {
            this.dynamicContents = this.dynamicContents.filter(x => x.elementId != data.elementId)
          }
        }
      },
    },
  },
  methods: {
    ...mapActions({
      wsSend: 'sendMessage',
    }),
    resetSelectedDynamicContent() {
      for(const dynamicContent of this.$refs.table.getVisibleAndSelectedRows()) {
        this.resetDynamicContent(dynamicContent.elementId);
      }
    },
    cloneCommands(commands) {
      return JSON.parse(JSON.stringify(commands));
    },
    encodeCommands(commands) {
      return commands.map(x => {
        const removeClassesArray = x.removeClasses.trim().split(" ").filter(y => y);
        const addClassesArray = x.addClasses.trim().split(" ").filter(y => y);

        return {
          elementId: x.elementId,
          removeClasses: removeClassesArray,
          addClasses: addClassesArray,
          source: x.source,
          delay: x.delay
        }
      });
    },
    decodeCommands(commands) {
      return commands.map(x => {
        const removeClasses = x.removeClasses.reduce((acc, x) => acc + x + " ", "").trim();
        const addClasses = x.addClasses.reduce((acc, x) => acc + x + " ", "").trim();

        return {
          elementId: x.elementId,
          removeClasses: removeClasses,
          addClasses: addClasses,
          source: x.source,
          delay: x.delay
        }
      });
    },
    executeCommands(commands) {
      this.wsSend({
        "type": "DynamicContent",
        "action": "Trigger",
        "commands": this.encodeCommands(commands)
      });
    },
    resetDynamicContent(elementId) {
      this.wsSend({
        "type": "DynamicContent",
        "action": "Reset",
        "elementId": elementId
      });
    },
  },
  created() {
    API.get('APIGateway', `/events/${this.$store.state.currentEvent}/state`, {})
    .then((result) => {
      this.dynamicContents = result.dynamic_content.map(x => {
        return {
          elementId: x.element_id,
          source: x.source,
          removedClasses: x.removed_classes,
          currentClasses: x.current_classes,
        }
      });
    })
    .catch((e) => {
      console.log(e)
    });

    API.get('APIGateway', `/events/${this.$store.state.currentEvent}`, {})
    .then((result) => {
      this.triggers = result.dynamic_content.triggers.map(x => {
        return {
          name: x.name,
          commands: this.decodeCommands(x.commands)
        }
      });
    })
    .catch((e) => {
      console.log(e)
    });
  },
}
</script>

<style scoped lang="scss">

.table thead th {
    vertical-align: top;
}

</style>
