<template>
  <div>
    <b-modal v-if="editPost" size="xl" id="edit-post-modal" title="Edit Post" hide-footer>
      <b-form-group 
        class="w-100"
        label="Name:"
        label-cols="2"
        label-class="font-weight-bold"
        content-cols="10">
        <b-form-input v-model="editPost.name"></b-form-input>
      </b-form-group>
      <b-form-group 
        class="w-100"
        label="Type:"
        label-cols="2"
        label-class="font-weight-bold"
        content-cols="10">
        <b-form-input v-model="editPost.type"></b-form-input>
      </b-form-group>
      <b-form-group 
        class="w-100"
        label="Description:"
        label-cols="2"
        label-class="font-weight-bold"
        content-cols="10">
        <b-form-input v-model="editPost.description"></b-form-input>
      </b-form-group>
      <b-form-group 
        class="w-100"
        label="Attributes:"
        label-cols="2"
        label-class="font-weight-bold"
        content-cols="10">
        <div class="mb-3" v-for="(attribute, i) of editPost.attributes" :key="i">
          <b-container fluid>
            <b-row>
              <div class="col-9 pl-0 pr-3">
                <b-button class="w-100 h-100" v-b-toggle="'attribute-edit'+i">{{attribute.name || "Attribute "+ i}}</b-button>
              </div>
              <div class="col-3 p-0">
                <b-button class="w-100" @click="editPost.attributes.splice(i, 1)"> Remove </b-button>
              </div>
            </b-row>
          </b-container>
          <b-collapse accordion="attribute-accordion" :id="'attribute-edit'+i" class="mt-3">
            <b-card>
              <b-form-group 
                class="w-100"
                label="Name:"
                label-cols="2"
                label-class="font-weight-bold"
                content-cols="10">
                <b-form-input
                  v-model="attribute.name"
                  placeholder="Enter Attribute name"
                ></b-form-input>
              </b-form-group>
              <b-form-group 
                class="w-100"
                label="Value:"
                label-cols="2"
                label-class="font-weight-bold"
                content-cols="10">
                <b-form-input
                  v-model="attribute.value"
                  placeholder="Enter Attribute value"
                ></b-form-input>
              </b-form-group>
            </b-card>
          </b-collapse>
        </div>
        <b-button class="w-100" @click="editPost.attributes.push({name: '', value: ''})">Add Attribute</b-button>
      </b-form-group>
      <b-button class="mr-2" @click="savePosts(); $bvModal.hide('edit-post-modal')">OK</b-button>
      <b-button @click="$bvModal.hide('edit-post-modal')">Cancel</b-button>
    </b-modal>
    <b-modal @show="newPost={name:'',type:'', description: '', attributes: []}" size="xl" id="create-post-modal" title="Create Post" hide-footer>
      <b-form-group 
        class="w-100"
        label="Name:"
        label-cols="2"
        label-class="font-weight-bold"
        content-cols="10">
        <b-form-input v-model="newPost.name"></b-form-input>
      </b-form-group>
      <b-form-group 
        class="w-100"
        label="Type:"
        label-cols="2"
        label-class="font-weight-bold"
        content-cols="10">
        <b-form-input v-model="newPost.type"></b-form-input>
      </b-form-group>
      <b-form-group 
        class="w-100"
        label="Description:"
        label-cols="2"
        label-class="font-weight-bold"
        content-cols="10">
        <b-form-input v-model="newPost.description"></b-form-input>
      </b-form-group>
      <b-form-group 
        class="w-100"
        label="Attributes:"
        label-cols="2"
        label-class="font-weight-bold"
        content-cols="10">
        <div class="mb-3" v-for="(attribute, i) of newPost.attributes" :key="i">
          <b-container fluid>
            <b-row>
              <div class="col-9 pl-0 pr-3">
                <b-button class="w-100 h-100" v-b-toggle="'attribute-edit'+i">{{attribute.name || "Attribute "+ i}}</b-button>
              </div>
              <div class="col-3 p-0">
                <b-button class="w-100" @click="newPost.attributes.splice(i, 1)"> Remove </b-button>
              </div>
            </b-row>
          </b-container>
          <b-collapse accordion="attribute-accordion" :id="'attribute-edit'+i" class="mt-3">
            <b-card>
              <b-form-group 
                class="w-100"
                label="Name:"
                label-cols="2"
                label-class="font-weight-bold"
                content-cols="10">
                <b-form-input
                  v-model="attribute.name"
                  placeholder="Enter Attribute name"
                ></b-form-input>
              </b-form-group>
              <b-form-group 
                class="w-100"
                label="Value:"
                label-cols="2"
                label-class="font-weight-bold"
                content-cols="10">
                <b-form-input
                  v-model="attribute.value"
                  placeholder="Enter Attribute value"
                ></b-form-input>
              </b-form-group>
            </b-card>
          </b-collapse>
        </div>
        <b-button class="w-100" @click="newPost.attributes.push({name: '', value: ''})">Add Attribute</b-button>
      </b-form-group>
      <b-button class="mr-2" @click="posts.push(newPost); savePosts(); $bvModal.hide('create-post-modal')">OK</b-button>
      <b-button @click="$bvModal.hide('create-post-modal')">Cancel</b-button>
    </b-modal>
    <b-modal id="delete-post-modal" title="Delete Post" hide-footer>
      <p>Are you sure you want to delete ALL selected posts?</p>
      <b-button class="mr-1" variant="danger" @click="deleteSelectedPosts(); savePosts(); $bvModal.hide('delete-post-modal')">Confirm</b-button>
      <b-button @click="$bvModal.hide('delete-post-modal')">Cancel</b-button>
    </b-modal>
    <Table ref="table" :rows="rows" :columns="columns" :actions="actions"></Table>
  </div>
</template>
<script>
import { API } from 'aws-amplify'

export default {
  data() {
    return {
      posts: [],
      newPost: {
        name: "",
        type: "",
        description: "",
        attributes: []
      },
      editPost: null,
    }
  },
  computed: {
    rows() {
      return this.posts;
    },
    columns() {
      const result = [];
      result.push({
        name: "Name",
        value: (row) => row.name
      });
      result.push({
        name: "Type",
        value: (row) => row.type
      });
      result.push({
        name: "Description",
        value: (row) => row.description
      });
      return result;
    },
    actions() {
      const result = [];
      result.push({
        name: "Create Post",
        action: () => this.$bvModal.show('create-post-modal'),
      });
      result.push({
        name: "Edit Post",
        action: () => {
          this.editPost = this.$refs.table.getVisibleAndSelectedRows()[0];
          this.$nextTick(() => this.$bvModal.show('edit-post-modal'));
        },
        oneSelected: true,
      });
      result.push({
        name: "Delete Posts",
        action: () => this.$bvModal.show('delete-post-modal'),
        dangerous: true,
        someSelected: true,
      });
      return result;
    },
  },
  methods: {
    deleteSelectedPosts() {
      const selected = this.$refs.table.getVisibleAndSelectedRows();
      this.posts = this.posts.filter(x => !selected.includes(x));
    },
    savePosts() {
      API.put('APIGateway', `/events/${this.$store.state.currentEvent}`, { body: {
        posts: this.posts.map(x => ({
          type: x.type,
          name: x.name,
          description: x.description,
          attributes: x.attributes,
        }))
      }})
      .then( response => {
        this.posts = response.posts.map(x => ({
          type: x.type,
          name: x.name,
          description: x.description,
          attributes: x.attributes,
        }));
      })
      .catch( e => {
        console.log(e);
      });
    }
  },
  created() {
    API.get('APIGateway', `/events/${this.$store.state.currentEvent}`, {})
    .then( response => {
      this.posts = response.posts.map(x => ({
        type: x.type,
        name: x.name,
        description: x.description,
        attributes: x.attributes,
      }));
    })
    .catch( e => {
      console.error(e);
    });
  }
}
</script>
<style scoped>
.table thead th {
    vertical-align: top;
}
</style>