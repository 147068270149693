<template>
  <b-col class="col-6 pt-3 pb-3">
    <b-card no-body align="center" class="h-100">
      <b-card-body class="d-flex flex-column">
        <h3><strong>Authentication</strong></h3>
        <b-form-group 
          class="w-100"
          label="Mode:"
          label-cols="2"
          label-class="font-weight-bold"
          content-cols="10"
        >
          <b-form-select v-model="auth.mode">
            <b-form-select-option value="GlobalPassword">Global Password</b-form-select-option>
            <b-form-select-option value="EmailPassword">Email Password</b-form-select-option>
            <b-form-select-option value="MagicLink">Magic Link</b-form-select-option>
            <b-form-select-option value="GetinForm">Getin Form</b-form-select-option>
            <b-form-select-option value="NoAuth">No Auth</b-form-select-option>
          </b-form-select>
        </b-form-group>
        <b-form-group 
          v-if="['EmailPassword', 'MagicLink', 'GetinForm'].includes(auth.mode)"
          class="w-100"
          label="Disable self registration:"
          label-cols="2"
          label-class="font-weight-bold"
          content-cols="10"
        >
          <b-form-checkbox class="text-left" v-model="auth.exclusive"></b-form-checkbox>
        </b-form-group>
        <b-form-group 
          v-if="['MagicLink', 'EmailPassword', 'GetinForm', 'GlobalPassword'].includes(auth.mode)"
          class="w-100"
          label="Event Page:"
          label-cols="2"
          label-class="font-weight-bold"
          content-cols="10"
        >
          <b-form-input
            v-model="auth.eventPage"
          ></b-form-input>
        </b-form-group>
        <b-form-group 
          v-if="['EmailPassword', 'MagicLink'].includes(auth.mode)"
          class="w-100"
          label="Register Page:"
          label-cols="2"
          label-class="font-weight-bold"
          content-cols="10"
        >
          <b-form-input
            v-model="auth.registerPage"
          ></b-form-input>
        </b-form-group>    
        <b-form-group
          v-if="['EmailPassword', 'MagicLink'].includes(auth.mode)"
          class="w-100"
          label="Register Success Page:"
          label-cols="2"
          label-class="font-weight-bold"
          content-cols="10"
        >
          <b-form-input
            v-model="auth.registerSuccessPage"
          ></b-form-input>
        </b-form-group>
        <b-form-group 
          v-if="['MagicLink', 'EmailPassword', 'GetinForm', 'GlobalPassword'].includes(auth.mode)"
          class="w-100"
          label="Login Page:"
          label-cols="2"
          label-class="font-weight-bold"
          content-cols="10"
        >
          <b-form-input
            v-model="auth.loginPage"
          ></b-form-input>
        </b-form-group>
        <b-form-group 
          v-if="['EmailPassword', 'MagicLink'].includes(auth.mode)"
          class="w-100"
          label="Register Form:"
          label-cols="2"
          label-class="font-weight-bold"
          content-cols="10"
        >
          <b-form-input
            v-model="auth.registerFormId"
          ></b-form-input>
        </b-form-group>
        <b-form-group 
          v-if="['EmailPassword', 'GetinForm', 'GlobalPassword'].includes(auth.mode)"
          class="w-100"
          label="Login Form Id:"
          label-cols="2"
          label-class="font-weight-bold"
          content-cols="10"
        >
          <b-form-input
            v-model="auth.loginFormId"
          ></b-form-input>
        </b-form-group>
        <b-form-group 
          v-if="['GlobalPassword', 'EmailPassword'].includes(auth.mode)"
          class="w-100"
          label="Password:"
          label-cols="2"
          label-class="font-weight-bold"
          content-cols="10"
        >
          <b-form-input
            v-model="auth.password"
          ></b-form-input>
        </b-form-group>
        <b-button class="align-self-end mt-auto" variant="success" @click="save()">Save</b-button>
      </b-card-body>
    </b-card>
  </b-col>
</template>
<script>
import { API } from 'aws-amplify';
import stringify from 'json-stable-stringify';

export default {
  props: {
    event: {
      type: Object,
    },
  },
  data() {
    return {
      auth: {},
    }
  },
  methods: {
    load(initial=false) {
      if (!initial && this.isDirty()) {
        return;
      }
      this.auth = JSON.parse(JSON.stringify(this.event.auth));
    },
    isDirty() {
      return stringify(this.event.auth) !== stringify(this.auth);
    },
    discard() {
      this.load(true);
    },
    async save() {
      await API.put('APIGateway', `/events/${this.$store.state.currentEvent}`, { body: {
        auth: this.auth
      }})
      .then( response => {
        this.$emit("update:event", response);

        this.$bvToast.toast(`Successfully updated auth settings.`, {
          title: `Success!`,
          toaster: "b-toaster-bottom-right",
          variant: "success",
          solid: true
        });
      })
      .catch( e => {
        this.$bvToast.toast(`Failed to update auth settings.`, {
          title: `Error!`,
          toaster: "b-toaster-bottom-right",
          variant: "error",
          solid: true
        });
      });
    },
  },
  watch: {
    event() {
      this.load();
    },
  },
  created() {
    this.load(true);
  }
};
</script>
<style scoped>
</style>