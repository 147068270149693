<template>
  <b-col class="col-6 pt-3 pb-3">
    <b-card no-body align="center" class="h-100">
      <b-card-body class="d-flex flex-column">
        <h3><strong>Q&A</strong></h3>
        <b-form-group 
          class="w-100"
          label="Display Id:"
          label-cols="2"
          label-class="font-weight-bold"
          content-cols="10">
          <b-form-input v-model="faq.displayId"></b-form-input>
        </b-form-group>
        <b-form-group 
          class="w-100"
          label="Question Form Id:"
          label-cols="2"
          label-class="font-weight-bold"
          content-cols="10">
          <b-form-input v-model="faq.questionFormId"></b-form-input>
        </b-form-group>
        <b-form-group 
          class="w-100"
          label="Holder Class:"
          label-cols="2"
          label-class="font-weight-bold"
          content-cols="10">
          <b-form-input v-model="faq.holderClass"></b-form-input>
        </b-form-group>
        <b-form-group 
          class="w-100"
          label="Question Class:"
          label-cols="2"
          label-class="font-weight-bold"
          content-cols="10">
          <b-form-input v-model="faq.questionClass"></b-form-input>
        </b-form-group>
        <b-form-group 
          class="w-100"
          label="Answer Class:"
          label-cols="2"
          label-class="font-weight-bold"
          content-cols="10">
          <b-form-input v-model="faq.answerClass"></b-form-input>
        </b-form-group>
        <b-button class="align-self-end mt-auto" variant="success" @click="save()">Save</b-button>
      </b-card-body>
    </b-card>
  </b-col>
</template>
<script>
import { API } from 'aws-amplify';
import stringify from 'json-stable-stringify';

export default {
  props: {
    event: {
      type: Object,
    },
  },
  data() {
    return {
      faq: null,
    }
  },
  methods: {
    load(initial=false) {
      if (!initial && this.isDirty()) {
        return;
      }
      if(this.event.has_faq && this.event.faq) {
        this.faq = JSON.parse(JSON.stringify(this.event.faq));
      } else {
        this.faq = {
          answerClass: "",
          displayId: "",
          holderClass: "",
          questionClass: "",
          questionFormId: ""
        }
      }
    },
    isDirty() {
      if(this.event.has_faq && this.event.faq) {
        return stringify(this.event.faq) !== stringify(this.faq);
      } else {
        return stringify({
          answerClass: "",
          displayId: "",
          holderClass: "",
          questionClass: "",
          questionFormId: ""
        }) !== stringify(this.faq);
      }
    },
    discard() {
      this.load(true);
    },
    async save() {
      await API.put('APIGateway', `/events/${this.$store.state.currentEvent}`, { body: {
        faq: this.faq
      }})
      .then( response => {
        this.faq = response.faq;

        this.$bvToast.toast(`Successfully updated faq settings.`, {
          title: `Success!`,
          toaster: "b-toaster-bottom-right",
          variant: "success",
          solid: true
        });
      })
      .catch( e => {
        this.$bvToast.toast(`Failed to update faq settings.`, {
          title: `Error!`,
          toaster: "b-toaster-bottom-right",
          variant: "error",
          solid: true
        });
      });
    }
  },
  watch: {
    event() {
      this.load();
    },
  },
  created() {
    this.load(true);
  }
};
</script>
<style scoped>
</style>