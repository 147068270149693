<template>
  <b-col class="col-6 pt-3 pb-3">
    <b-modal v-if="currentTemplate" id="edit-template-modal" size="xl" title="Edit E-Mail Template" hide-footer>
      <div class="d-flex flex-column">
        <b-form-group 
          class="w-100"
          label="Name:"
          label-cols="2"
          label-class="font-weight-bold"
          content-cols="10">
          <b-form-input v-model="currentTemplate.name"></b-form-input>
        </b-form-group>
        <b-form-group 
          class="w-100"
          label="Language:"
          label-cols="2"
          label-class="font-weight-bold"
          content-cols="10">
          <b-form-select v-model="currentTemplate.lang" :options="languageOptions"></b-form-select>
        </b-form-group>
        <b-form-group 
          class="w-100"
          label="Html:"
          label-cols="2"
          label-class="font-weight-bold"
          content-cols="10">
          <b-form-textarea
            class="mb-3"
            id="textarea"
            v-model="currentTemplate.html"
            placeholder="Enter Html message..."
            rows="3"
          ></b-form-textarea>
          <b-form-file @input="loadHtmlFromFile"
            placeholder="Import html from file"
          ></b-form-file>
        </b-form-group>
        <b-form-group 
          class="w-100"
          label="Text:"
          label-cols="2"
          label-class="font-weight-bold"
          content-cols="10">
          <b-form-textarea
            class="mb-3"
            id="textarea"
            v-model="currentTemplate.text"
            placeholder="Enter Plain message..."
            rows="3"
          ></b-form-textarea>
          <b-form-file @input="loadTextFromFile"
            placeholder="Import text from file"
          ></b-form-file>
        </b-form-group>
        <b-form-group 
          class="w-100"
          label="Calendar Invite(.ics):"
          label-cols="2"
          label-class="font-weight-bold"
          content-cols="10">
          <b-form-textarea
            class="mb-3"
            id="textarea"
            v-model="currentTemplate.ics"
            placeholder="Enter ics file.."
            rows="3"
          ></b-form-textarea>
          <b-form-file @input="loadIcsFromFile"
            placeholder="Import ics from file"
          ></b-form-file>
        </b-form-group>
        <b-form-group 
          class="w-100"
          label="Subject:"
          label-cols="2"
          label-class="font-weight-bold"
          content-cols="10">
          <b-form-input v-model="currentTemplate.subject"></b-form-input>
        </b-form-group>
        <b-button class="align-self-end mt-auto" @click="$bvModal.hide('edit-template-modal')">Ok</b-button>
      </div>
    </b-modal>
    <b-card no-body align="center" class="h-100">
      <b-card-body class="d-flex flex-column">
        <h3><strong>E-Mail Templates</strong></h3>
        <b-form-group 
        class="w-100"
        label="E-Mail Templates:"
        label-cols="2"
        label-class="font-weight-bold"
        content-cols="10"
        >
          <b-container fluid>
            <b-row class="mb-3" v-for="(emailTemplate, i) in emailTemplates" :key="i">
            <b-col class="col-9 pl-0 pr-3">
              <b-button class="w-100 h-100" @click="currentTemplate=emailTemplate" v-b-modal="'edit-template-modal'"> 
              {{emailTemplate.name ? emailTemplate.name + " (" + emailTemplate.lang + ")" : ""}} 
              </b-button>
            </b-col>
            <b-col class="col-3 p-0">
              <b-button class="w-100" @click="emailTemplates = emailTemplates.filter((_, j) => i != j)"> Remove </b-button>
            </b-col>
            </b-row>
          </b-container>  
          <b-button class="w-100" @click="emailTemplates.push({name: '', html: '', text: '', subject: '', lang: '', ics: ''}); currentTemplate = emailTemplates.slice(-1)[0]" v-b-modal="'edit-template-modal'">Add E-Mail Template</b-button>
        </b-form-group>
        <b-button class="align-self-end mt-auto" variant="success" @click="save()">Save</b-button>
      </b-card-body>
    </b-card>
  </b-col>
</template>
<script>
import { API } from 'aws-amplify';
import PromiseFileReader from 'promise-file-reader';
import stringify from 'json-stable-stringify';

export default {
  props: {
    event: {
      type: Object,
    },
  },
  data() {
    return {
      emailTemplates: [],
      currentTemplate: null,
      languageOptions: [
        {value: "", text: "Please select a language"},
        {value: "en", text: "English"},
        {value: "es", text: "Spanish"},
        {value: "es-lat", text: "Spanish (Latin America)"},
        {value: "fr", text: "French"},
        {value: "it", text: "Italian"},
        {value: "pt", text: "Portuguese"},
        {value: "ru", text: "Russian"},
        {value: "pl", text: "Polish"},
        {value: "tr", text: "Turkish"},
        {value: "ar", text: "Arabic"},
        {value: "jp", text: "Japanese"},
        {value: "zh", text: "Chinese"},
        {value: "hi", text: "Hindi"},
        {value: "ko", text: "Korean"},
        {value: "el", text: "Greek"},
        {value: "sv", text: "Swedish"},
        {value: "de", text: "German"},
        {value: "nl", text: "Dutch"},
        {value: "da", text: "Danish"}
      ]
    }
  },
  methods: {
    loadTextFromFile(file) {
      PromiseFileReader.readAsText(file)
      .then(data => {
        this.currentTemplate.text = data;
      })
      .catch(e => {
        console.error(e);
      });
    },
    loadIcsFromFile(file) {
      PromiseFileReader.readAsText(file)
      .then(data => {
        this.currentTemplate.ics = data;
      })
      .catch(e => {
        console.error(e);
      });
    },
    loadHtmlFromFile(file) {
      PromiseFileReader.readAsText(file)
      .then(data => {
        this.currentTemplate.html = data;
      })
      .catch(e => {
        console.error(e);
      });
    },
    load(initial=false) {
      if (!initial && this.isDirty()) {
        return;
      }
      this.emailTemplates = JSON.parse(JSON.stringify(this.event.email_templates));
    },
    isDirty() {
      return stringify(this.event.email_templates) !== stringify(this.emailTemplates);
    },
    discard() {
      this.load(true);
    },
    async save() {
      await API.put('APIGateway', `/events/${this.$store.state.currentEvent}`, { body: {
        email_templates: this.emailTemplates
      }})
      .then( response => {
        this.$emit("update:event", response);

        this.$bvToast.toast(`Successfully updated email templates.`, {
          title: `Success!`,
          toaster: "b-toaster-bottom-right",
          variant: "success",
          solid: true
        });
      })
      .catch( e => {
        this.$bvToast.toast(`Failed to update email templates.`, {
          title: `Error!`,
          toaster: "b-toaster-bottom-right",
          variant: "error",
          solid: true
        });
      });
    },
  },
  watch: {
    event() {
      this.load();
    },
  },
  created() {
    this.load(true);
  }
};
</script>
<style scoped>
</style>