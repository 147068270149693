<template>
  <div>
    <b-modal id="delete-event-modal" title="Delete Event" hide-footer>
      <p>Are you sure you want to delete ALL selected events?</p>
      <b-button class="mr-1" variant="danger" @click="deleteSelectedEvents(); $bvModal.hide('delete-event-modal')">Confirm</b-button>
      <b-button @click="$bvModal.hide('delete-event-modal')">Cancel</b-button>
    </b-modal>
    <b-modal @show="name=''" id="create-event-modal" title="Create Event" hide-footer>
      <p>Enter Name:</p>
      <b-form-input
        class="mb-3" 
        id="name"
        v-model="name"
        placeholder="Enter Name"
      ></b-form-input>
      <b-button class="mr-1" :disabled="!name" @click="createEvent(name); $bvModal.hide('create-event-modal')">Create</b-button>
      <b-button @click="$bvModal.hide('create-event-modal')">Cancel</b-button>
    </b-modal>
    <Table ref="table" :rows="rows" :columns="columns" :actions="actions" :filters="filters">
      <b-col sm="4" md="3" lg="2" slot="header">
        <b-form-group
          slot="header"
          class="w-100 mb-0 align-items-center"
          label="Show archived:"
          label-cols="10"
          label-class="font-weight-bold"
          label-for="show-archived-events-checkbox"
          content-cols="2"
        >
          <b-form-checkbox id="show-archived-events-checkbox" class="text-left" v-model="showArchived"></b-form-checkbox>
        </b-form-group>
      </b-col>
    </Table>
  </div>
</template>

<script>
import { API } from 'aws-amplify'

export default {
  data() {
    return {
      events: [],
      showArchived: false,
      name: ""
    }
  },
  computed: {
    rows() {
      return this.events;
    },
    columns() {
      const result = [];
      result.push({
        name: "Name",
        value: (row) => row.archived ? `(archived) ${row.name}` : row.name
      });
      result.push({
        name: "Creation Date",
        value: (row) => row.creationDate
      });
      result.push({
        name: "ID",
        value: (row) => row.id
      });
      return result;
    },
    actions() {
      const result = [];
      result.push({
        name: "Create Event",
        action: () => this.$bvModal.show('create-event-modal'),
      });
      result.push({
        name: "Manage Event",
        action: () => {
          const event = this.$refs.table.getVisibleAndSelectedRows()[0];
          this.setEvent(event.id);
        },
        oneSelected: true,
      });
      result.push({
        name: "Archive Events",
        action: () => this.archiveSelectedEvents(),
        someSelected: true,
      });
      result.push({
        name: "Unarchive Events",
        action: () => this.unarchiveSelectedEvents(),
        someSelected: true,
      });
      result.push({
        name: "Delete Event",
        action: () => this.$bvModal.show('delete-event-modal'),
        dangerous: true,
        someSelected: true,
      });
      return result;
    },
    filters() {
      const result = [];
      result.push((row) => this.showArchived ? true : !row.archived);
      return result;
    },
  },
  methods: {
    setEvent(id) {
      this.$store.commit("setCurrentEvent", id);
      this.$router.push(`/event/guests`);
    },
    deleteSelectedEvents() {
      for(const event of this.$refs.table.getVisibleAndSelectedRows()) {
        API.del('APIGateway', `/events/${event.id}`, {})
        .then((result) => {
          this.events = this.events.filter(x => x.id != event.id);
        })
        .catch((e) => {
          console.log(e)
        })
      }
    },
    archiveSelectedEvents() {
      for(const event of this.$refs.table.getVisibleAndSelectedRows()) {
        API.put('APIGateway', `/events/${event.id}`, {
          body: {
            archived: true,
          },
        })
        .then((result) => {
          event.archived = true;
        })
        .catch((e) => {
          console.log(e)
        });
      }
    },
    unarchiveSelectedEvents() {
      for(const event of this.$refs.table.getVisibleAndSelectedRows()) {
        API.put('APIGateway', `/events/${event.id}`, {
          body: {
            archived: false,
          },
        })
        .then((result) => {
          event.archived = false;
        })
        .catch((e) => {
          console.log(e)
        });
      }
    },
    createEvent(name) {
      API.post('APIGateway', `/events`, {
        body: {
          name: name
        }
      })
      .then((result) => {
        this.events.push({
          name: result.name,
          id: result.id,
          archived: result.archived,
          creationDate: result.creation_date
        })
      })
      .catch((e) => {
        console.log(e)
      })
    }
  },
  created() {
    this.$store.commit("setCurrentEvent", "");

    API.get('APIGateway', '/events', {})
    .then((result) => {
      this.events = result.events.map(x => {
        return {
          id: x.id,
          name: x.name,
          archived: x.archived,
          creationDate: x.creation_date,
        }
      });
    })
    .catch(e => console.error(e));
  }
}
</script>

<style scoped>
.table thead th {
    vertical-align: top;
}
</style>
