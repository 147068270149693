<template>
  <amplify-authenticator username-alias="email">
    <div v-if="loaded" id="app">
      <Menubar></Menubar>
      <div id="main">
        <router-view id="main"></router-view>
      </div>
      <Footer></Footer>
    </div>
  </amplify-authenticator>
</template>

<script>
import Footer from './components/Footer.vue'
import Menubar from './components/Menubar.vue'
import { onAuthUIStateChange } from '@aws-amplify/ui-components'

export default {
  name: 'BillmannLandingpageAdminInterface',
  components: {
    Menubar,
    Footer
  },
  data() {
    return {
      loaded: false
    }
  },
  created () {
    this.unsubscribeAuth = onAuthUIStateChange((authState, authData) => {
      try {
        if (!!authData) {
          this.loaded = true;
          this.$store.commit("setGroups", authData.signInUserSession.accessToken.payload['cognito:groups']);
          this.$store.commit("setUsername", authData.attributes.email);
        }
      } catch (e) {
        console.error(e);
      }
    })
  },
  beforeUnmount () {
    this.unsubscribeAuth()
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  width: 100vw;
  margin-top: 0px;

  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

#main {
  flex: 1;
}
</style>
