<template>
  <b-col class="col-6 pt-3 pb-3">
    <b-card no-body align="center" class="h-100">
      <b-card-body class="d-flex flex-column">
        <h3><strong>Live Chat</strong></h3>
        <b-form-group 
          class="w-100"
          label="Display Id:"
          label-cols="2"
          label-class="font-weight-bold"
          content-cols="10">
          <b-form-input v-model="liveChat.displayId"></b-form-input>
        </b-form-group>
        <b-form-group 
          class="w-100"
          label="Message Form Id:"
          label-cols="2"
          label-class="font-weight-bold"
          content-cols="10">
          <b-form-input v-model="liveChat.sendMessageFormId"></b-form-input>
        </b-form-group>
        <b-form-group 
          class="w-100"
          label="Display Message Count:"
          label-cols="2"
          label-class="font-weight-bold"
          content-cols="10">
          <b-form-input type="number" v-model.number="liveChat.count"></b-form-input>
        </b-form-group>
        <b-form-group 
          class="w-100"
          label="Holder Class:"
          label-cols="2"
          label-class="font-weight-bold"
          content-cols="10">
          <b-form-input v-model="liveChat.holderClass"></b-form-input>
        </b-form-group>
        <b-form-group 
          class="w-100"
          label="Name Class:"
          label-cols="2"
          label-class="font-weight-bold"
          content-cols="10">
          <b-form-input v-model="liveChat.nameClass"></b-form-input>
        </b-form-group>
        <b-form-group 
          class="w-100"
          label="Separator Class:"
          label-cols="2"
          label-class="font-weight-bold"
          content-cols="10">
          <b-form-input v-model="liveChat.separatorClass"></b-form-input>
        </b-form-group>
        <b-form-group 
          class="w-100"
          label="Message Class:"
          label-cols="2"
          label-class="font-weight-bold"
          content-cols="10">
          <b-form-input v-model="liveChat.messageClass"></b-form-input>
        </b-form-group>
        <b-button class="align-self-end mt-auto" variant="success" @click="save()">Save</b-button>
      </b-card-body>
    </b-card>
  </b-col>
</template>
<script>
import { API } from 'aws-amplify';
import stringify from 'json-stable-stringify';

export default {
  props: {
    event: {
      type: Object,
    },
  },
  data() {
    return {
      liveChat: null,
    }
  },
  methods: {
    load(initial=false) {
      if (!initial && this.isDirty()) {
        return;
      }
      if(this.event.has_live_chat && this.event.live_chat) {
        this.liveChat = JSON.parse(JSON.stringify(this.event.live_chat));
      } else {
        this.liveChat = {
          count: 0,
          displayId: "",
          holderClass: "",
          messageClass: "",
          nameClass: "",
          sendMessageFormId: "",
          separatorClass: ""
        }
      }
    },
    isDirty() {
      if(this.event.has_live_chat && this.event.live_chat) {
        return stringify(this.event.live_chat) !== stringify(this.liveChat);
      } else {
        return stringify({
          count: 0,
          displayId: "",
          holderClass: "",
          messageClass: "",
          nameClass: "",
          sendMessageFormId: "",
          separatorClass: ""
        }) !== stringify(this.liveChat);
      }
    },
    discard() {
      this.load(true);
    },
    async save() {
      await API.put('APIGateway', `/events/${this.$store.state.currentEvent}`, { body: {
        live_chat: this.liveChat
      }})
      .then( response => {
        this.$emit("update:event", response);

        this.$bvToast.toast(`Successfully updated live chat settings.`, {
          title: `Success!`,
          toaster: "b-toaster-bottom-right",
          variant: "success",
          solid: true
        });
      })
      .catch( e => {
        this.$bvToast.toast(`Failed to update live chat settings.`, {
          title: `Error!`,
          toaster: "b-toaster-bottom-right",
          variant: "error",
          solid: true
        });
      });
    },
  },
  watch: {
    event() {
      this.load();
    },
  },
  created() {
    this.load(true);
  }
};
</script>
<style scoped>
</style>