import Vue from 'vue'
import Vuex from 'vuex'
import { API } from 'aws-amplify';
import createPersistedState from "vuex-persistedstate"

Vue.use(Vuex);

export const store = new Vuex.Store({
  state: {
    currentEvent: "",
    username: "",
    groups: [],
    socket: {
      retries: 0,
      maxRetries: 3,
      latestMessage: null,
    },
    keepAliveTimer: null
  },
  getters: {
    getLatestMessage: (state) => state.socket.latestMessage,
  },
  actions: {
    async sendMessage({ state, dispatch }, message) {
      Vue.prototype.$socket.send(
        JSON.stringify(message),
      );
    },
  },
  mutations: {
    setCurrentEvent(state, payload) {
      state.currentEvent = payload;

      Vue.prototype.$disconnect();
      if (payload) {
        API.post('APIGateway', `/events/${payload}/login`, { body: {} })
        .then((result) => {
          Vue.prototype.$connect(`${process.env.VUE_APP_WEBSOCKET_URL}?token=${encodeURIComponent(result.token)}`);
        });
      }
    },
    setUsername(state, payload) {
      state.username = payload;
    },
    setGroups(state, payload) {
      state.groups = payload;
    },
    SOCKET_ONOPEN(state, event) {
      state.keepAliveTimer = setInterval(() => {
        console.log('keep alive');
        Vue.prototype.$socket.send(
          JSON.stringify({
            "type": "KeepAlive"
          }),
        );
      }, 1000 * 30);
      state.socket.retries = 0;
      Vue.prototype.$socket = event.currentTarget;
    },
    SOCKET_ONCLOSE(state, event) {
      if (state.keepAliveTimer) {
        clearInterval(state.keepAliveTimer);
        state.keepAliveTimer = null;
      }
      if (![1000, 1005].includes(event.code) && state.socket.retries < state.socket.maxRetries) {
        state.socket.retries += 1;
        API.post('APIGateway', `/events/${state.currentEvent}/login`, { body: {} })
        .then((result) => {
          Vue.prototype.$disconnect();
          Vue.prototype.$connect(`${process.env.VUE_APP_WEBSOCKET_URL}?token=${encodeURIComponent(result.token)}`);
        });
      } else {
        console.log('websocket closed');
      }
    },
    SOCKET_ONERROR(state, event) {
      console.error(state, event);
    },
    SOCKET_ONMESSAGE(state, rawMessage) {
      const message = JSON.parse(rawMessage.data);
      state.socket.latestMessage = message;
    },
  },
  plugins: [createPersistedState({ 
    rehydrated: ({state}) => {
      Vue.nextTick(() => {
        Vue.prototype.$disconnect();
        if (state.currentEvent) {
          API.post('APIGateway', `/events/${state.currentEvent}/login`, { body: {} })
          .then((result) => {
            Vue.prototype.$connect(`${process.env.VUE_APP_WEBSOCKET_URL}?token=${encodeURIComponent(result.token)}`);
          });
        }
      });
    },
  })],
});

