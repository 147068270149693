<template>
  <b-col class="col-12 pt-3 pb-3">
    <b-modal v-if="currentPage" id="edit-page-modal" size="xl" title="Edit Page" hide-footer>
      <div class="d-flex flex-column">
        <b-form-group 
          class="w-100"
          label="Path:"
          label-cols="2"
          label-class="font-weight-bold"
          content-cols="10">
          <b-form-input v-model="currentPage.path"></b-form-input>
        </b-form-group>
        <b-form-group 
          class="w-100"
          label="File Name:"
          label-cols="2"
          label-class="font-weight-bold"
          content-cols="10">
          <b-form-select v-model="currentPage.fileName" :options="htmlFileOptions"></b-form-select>
        </b-form-group>
        <b-button class="align-self-end mt-auto" @click="$bvModal.hide('edit-page-modal')">Ok</b-button>
      </div>
    </b-modal>
    <b-card no-body align="center" class="h-100">
      <b-card-body class="d-flex flex-column">
        <h3><strong>Website</strong></h3>
        <div class="d-flex">
        <div class="d-inline-block w-50 pr-3">
          <b-form-group 
          class="w-100"
          label="Upload:"
          label-cols="2"
          label-class="font-weight-bold"
          content-cols="10"
          >
            <b-form-file
              class="mb-3"
              v-model="uploadfiles"
              placeholder="Choose directory to upload..."
              directory
              multiple
              no-traverse
            ></b-form-file>
          </b-form-group>
          <b-form-group 
          class="w-100"
          label="Pages:"
          label-cols="2"
          label-class="font-weight-bold"
          content-cols="10"
          >
            <b-container fluid>
              <b-row class="mb-3" v-for="(page, i) in pages" :key="i">
              <b-col class="col-9 pl-0 pr-3">
                <b-button class="w-100 h-100" @click="currentPage=page" v-b-modal="'edit-page-modal'"> 
                {{page.path ? page.path : "DEFAULT"}} 
                </b-button>
              </b-col>
              <b-col class="col-3 p-0">
                <b-button class="w-100" @click="pages = pages.filter(x => page != x)"> Remove </b-button>
              </b-col>
              </b-row>
            </b-container>  
          <b-button class="w-100 mb-3" @click="pages.push({path: '', fileName: ''}); currentPage=pages.slice(-1)[0]" v-b-modal="'edit-page-modal'">Add Page</b-button>
          </b-form-group>
        </div>
        <div class="d-inline-block w-50 pr-3">
          <b-form-group 
          class="w-100 mb-3"
          label="Files:"
          label-cols="2"
          label-class="font-weight-bold"
          content-cols="10"
          >
            <table class="table">
              <thead>
              <tr>
                <th>Path</th>
                <th>Private</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(file, i) in files" :key="i">
                <td> {{ file.fileName }} </td>
                <td>
                  <div class="form-check">
                    <input
                    class="form-check-input"
                    type="checkbox"
                    v-model="file.private"
                    />
                  </div>
                </td>
              </tr>
              </tbody>
            </table>
          </b-form-group>
        </div>
        </div>
        <b-button class="align-self-end mt-auto" variant="success" @click="save()">Save</b-button>
      </b-card-body>
    </b-card>
  </b-col>
</template>
<script>
import { API } from 'aws-amplify';
import axios from 'axios';
import stringify from 'json-stable-stringify';

export default {
  props: {
    event: {
      type: Object,
    },
  },
  data() {
    return {
      files: [],
      pages: [],
      uploadfiles: [],
      currentPage: null,
    }
  },
  computed: {
    htmlFileOptions() {
      const htmlFiles = this.files.filter(x => x.fileName.endsWith(".html"));
      return htmlFiles.map(x => { return {value: x.fileName, text: x.fileName} });
    },
  },
  methods: {
    load(initial=false) {
      if (!initial && this.isDirty()) {
        return;
      }
      this.files = JSON.parse(JSON.stringify(this.event.files));
      this.pages = JSON.parse(JSON.stringify(this.event.pages));
    },
    isDirty() {
      if (this.uploadfiles && this.uploadfiles.length > 0) {
        return true;
      }

      return stringify(this.event.files) !== stringify(this.files) ||
        stringify(this.event.pages) !== stringify(this.pages);
    },
    discard() {
      this.load(true);
    },
    async save() {
      if(this.uploadfiles && this.uploadfiles.length > 0) {
        try {
          const files = this.uploadfiles;
          this.uploadfiles = null;
          const fileNames = files.map(x => x.$path.substring(x.$path.indexOf("/") + 1));

          const uploadResponse = await API.post('APIGateway', `/events/${this.$store.state.currentEvent}/upload`, { body : {
            file_names: fileNames
          }});

          const pendingUploads = uploadResponse.upload_urls.map(async (uploadUrl, idx) => {
            const file = files[idx];

            for(let i = 0; i < 3; i += 1) {
              try {
                const response = await axios({
                  method: "put",
                  url: uploadUrl,
                  data: file,
                  headers: {
                    "Content-Type": file.type,
                    "Cache-Control": "max-age=86400"
                  }
                });
                return response;
              } catch(e) {
                console.error(`Failed to upload file (try ${i}/3)`);
              }
            }

            throw new RuntimeError("Failed to upload file.");
          });

          await Promise.all(pendingUploads);

          this.$bvToast.toast(`Successfully uploaded ${files.length} files.`, {
            title: `Success!`,
            toaster: "b-toaster-bottom-right",
            variant: "success",
            solid: true
          });

          console.log(uploadResponse);
          this.files = uploadResponse.files;
        } catch(err) {
          console.error(err);
          this.$bvToast.toast(`Upload of files failed.`, {
            title: `Error!`,
            toaster: "b-toaster-bottom-right",
            variant: "error",
            solid: true
          });
        }
      }

      try {
        await API.post('APIGateway', `/events/${this.$store.state.currentEvent}/invalidate`, {body: {}});
        this.$bvToast.toast(`Successfully invalidated CDN.`, {
          title: `Success!`,
          toaster: "b-toaster-bottom-right",
          variant: "success",
          solid: true
        });
      } catch(err) {
        console.error(err);
        this.$bvToast.toast(`Failed to invalidate CDN.`, {
          title: `Error!`,
          toaster: "b-toaster-bottom-right",
          variant: "error",
          solid: true
        });
      }

      try {
        const updateResponse = await API.put('APIGateway', `/events/${this.$store.state.currentEvent}`, { body: {
          pages: this.pages,
          files: this.files
        }});

        this.$emit("update:event", updateResponse);

        this.$bvToast.toast(`Successfully updated pages.`, {
          title: `Success!`,
          toaster: "b-toaster-bottom-right",
          variant: "success",
          solid: true
        });
      } catch(err) {
        console.error(err);
        this.$bvToast.toast(`Failed to update pages.`, {
          title: `Error!`,
          toaster: "b-toaster-bottom-right",
          variant: "error",
          solid: true
        });
      }
    },
  },
  watch: {
    event() {
      this.load();
    },
  },
  created() {
    this.load(true);
  }
};
</script>
<style scoped>
</style>