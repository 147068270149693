<template>
  <b-col class="col-6 pt-3 pb-3">
    <b-card no-body align="center" class="h-100">
      <b-card-body class="d-flex flex-column">
        <h3><strong>Survey</strong></h3>
        <b-form-group 
          class="w-100"
          label="Form Id:"
          label-cols="2"
          label-class="font-weight-bold"
          content-cols="10">
          <b-form-input v-model="survey.formId"></b-form-input>
        </b-form-group>
        <b-form-group 
          class="w-100"
          label="Container Id:"
          label-cols="2"
          label-class="font-weight-bold"
          content-cols="10">
          <b-form-input v-model="survey.containerId"></b-form-input>
        </b-form-group>
        <b-form-group 
          class="w-100"
          label="Hide after submit:"
          label-cols="2"
          label-class="font-weight-bold"
          content-cols="10">
          <b-form-checkbox class="text-left" v-model="survey.once"></b-form-checkbox>
        </b-form-group>
        <b-button class="align-self-end mt-auto" variant="success" @click="save()">Save</b-button>
      </b-card-body>
    </b-card>
  </b-col>
</template>
<script>
import { API } from 'aws-amplify';
import stringify from 'json-stable-stringify';

export default {
  props: {
    event: {
      type: Object,
    },
  },
  data() {
    return {
      survey: null,
    }
  },
  methods: {
    load(initial=false) {
      if (!initial && this.isDirty()) {
        return;
      }
      if (this.event.has_survey && this.event.survey) {
        this.survey = JSON.parse(JSON.stringify(this.event.survey));
      } else {
        this.survey = {
          formId: "",
          containerId: "",
          once: false,
        };
      }
    },
    isDirty() {
      if (this.event.has_survey && this.event.survey) {
        return stringify(this.event.survey) !== stringify(this.survey);
      } else {
        return stringify({
          formId: "",
          containerId: "",
          once: false,
        }) !== stringify(this.survey);
      }
    },
    discard() {
      this.load(true);
    },
    async save() {
      await API.put('APIGateway', `/events/${this.$store.state.currentEvent}`, { body: {
        survey: this.survey
      }})
      .then( response => {
        this.$emit("update:event", response);

        this.$bvToast.toast(`Successfully updated survey settings.`, {
          title: `Success!`,
          toaster: "b-toaster-bottom-right",
          variant: "success",
          solid: true
        });
      })
      .catch( e => {
        this.$bvToast.toast(`Failed to update survey settings.`, {
          title: `Error!`,
          toaster: "b-toaster-bottom-right",
          variant: "error",
          solid: true
        });
      });
    },
  },
  watch: {
    event() {
      this.load();
    },
  },
  created() {
    this.load(true);
  }
};
</script>
<style scoped>
</style>