import Vue from 'vue'
import VueRouter from 'vue-router'
import Event from '@/components/views/Event.vue'
import EventList from '@/components/views/EventList.vue'
import AdminList from '@/components/views/AdminList.vue'
import GuestList from '@/components/event/GuestList.vue'
import DynamicContent from '@/components/event/DynamicContent.vue'
import Questions from '@/components/event/Questions.vue'
import ParticleEffects from '@/components/event/ParticleEffects.vue'
import Settings from '@/components/event/Settings.vue'
import Chat from '@/components/event/Chat.vue'
import Posts from '@/components/event/Posts.vue'
import Translations from '@/components/event/Translations.vue'

Vue.use(VueRouter)

const routes = [
  { 
    path: '/', 
    component: EventList,
    name: 'home'
  },
  { 
    path: '/admins', 
    component: AdminList,
    name: 'admins'
  },
  { 
    path: '/events',
    component: EventList,
    name: 'events'
  },
  {
    path: '/event',
    component: Event,
    name: 'event',
    children: [
      {
        path: 'guests',
        component: GuestList,
        name: 'event-guest-list'
      },
      {
        path: 'dynamic-content',
        component: DynamicContent,
        name: 'event-dynamic-content'
      },
      {
        path: 'questions',
        component: Questions,
        name: 'event-questions'
      },
      {
        path: 'chat',
        component: Chat,
        name: 'event-chat'
      },
      {
        path: 'particle-effects',
        component: ParticleEffects,
        name: 'event-particle-effects'
      },
      {
        path: 'settings',
        component: Settings,
        name: 'event-settings'
      },
      {
        path: 'posts',
        component: Posts,
        name: 'event-posts'
      },
      {
        path: 'translations',
        component: Translations,
        name: 'event-translations'
      }
    ]
  }
]

export const router = new VueRouter({
  routes,
  mode: 'hash'
})
