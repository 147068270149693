<template>
  <div>
      <b-button @click="sendParticle(1)">Send Particle</b-button>
      <b-button class="ml-3" @click="download">Download Log</b-button>
  </div>
</template>

<script>
import { API } from 'aws-amplify'
import { mapGetters, mapActions } from 'vuex';

export default {
  computed: {
    ...mapGetters({
      latestMessage: 'getLatestMessage',
    }),
  },
  watch: {
    latestMessage: {
      handler() {
        const data = this.latestMessage;
        if (data.type !== 'ParticleEffects') {
          return;
        }

        console.log(data.count);
      },
    },
  },
  methods: {
    ...mapActions({
      wsSend: 'sendMessage',
    }),
    download() {
      API.get('APIGateway', `/events/${this.$store.state.currentEvent}/likes`, {})
      .then( response => {
        const data = "ts,count\n" + response.likes
        .sort((a, b) => a.ts > b.ts)
        .map(like => {
          return String(like.ts) + ",1";
        })
        .join("\n")

        const blob = new Blob([data], {type: 'text/csv'})
        const e = document.createEvent('MouseEvents'),
        a = document.createElement('a');
        a.download = "likes.csv";
        a.href = window.URL.createObjectURL(blob);
        a.dataset.downloadurl = ['text/csv', a.download, a.href].join(':');
        e.initEvent('click', true, false, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null);
        a.dispatchEvent(e);
      })
      .catch( err => {
        console.error(err);
      })
    },
    sendParticle(count) {
      this.wsSend({
        type: "ParticleEffects",
        count: count
      });
    },
  },
}
</script>
<style scoped>
</style>