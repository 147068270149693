import Vue from 'vue'

import './custom.scss'
import '@aws-amplify/ui-vue'

import BootstrapVue, { ModalPlugin } from 'bootstrap-vue'
import { Amplify, Auth } from 'aws-amplify'
import VueNativeSock from 'vue-native-websocket';

import App from './App.vue'

import Table from '@/components/utility/Table.vue';
import Tooltip from '@/components/utility/Tooltip.vue';

import { router } from './router'
import { store } from './store'

import {
  applyPolyfills,
  defineCustomElements
} from '@aws-amplify/ui-components/loader'

Amplify.configure({
  Auth: {
    region: 'eu-central-1',
    userPoolId: process.env.VUE_APP_COGNITO_USER_POOL_ID,
    userPoolWebClientId: process.env.VUE_APP_COGNITO_USER_POOL_WEB_CLIENT_ID
  },
  API: {
    endpoints: [
      {
        name: 'APIGateway',
        endpoint: process.env.VUE_APP_BACKEND_API_BASE_PATH,
        custom_header: async() => {
          return { Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`}
        }
      }
    ]
  }
})

applyPolyfills().then(() => {
  defineCustomElements(window)
});

Vue.use(BootstrapVue);
Vue.use(ModalPlugin);
Vue.use(VueNativeSock, process.env.VUE_APP_WEBSOCKET_URL, {
  connectManually: true,
  reconnection: false,
  store,
});

Vue.config.productionTip = false

Vue.component("Tooltip", Tooltip);
Vue.component("Table", Table);

new Vue({
  render: h => h(App),
  router,
  store,
}).$mount('#app');
